import { FiBookOpen, FiCreditCard, FiDollarSign, FiUsers } from 'react-icons/fi'
import { FaMoneyBillTrendUp } from "react-icons/fa6";

import { AppointmentWidget, BarChart, NumberIcon } from '../../components/widgets'
import Styles from './home.module.scss'
import GlobalStyles from '../../utils/sass/global.module.scss'
import Chart from 'react-google-charts';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardStat } from '../../store/profile/profileActions';

const DashboardAdmin = () => {
    const userChartData = [
        ["Month", "Tutors", "Students"],
        ["Jan", 200, 400],
        ["Feb", 214, 400],
        ["Mar", 12, 400],
        ["Apr", 1000, 400],
        ["May", 84, 400],
        ["June", 15, 400],
        ["July", 290, 400],
        ["Aug", 140, 400],
        ["Sept", 320, 400],
        ["Oct", 540, 400],
        ["Nov", 105, 460],
        ["Dec", 209, 1120]
    ];

    const usersChartOptions = {
        chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: 2014-2017",
            isStacked: true,
        },
        colors: ["#DF2B47", "#E0BD48", "#191D51"],
        isStacked: true,
    };


    const data = [
        ["Language", "Speakers"],
        ["German", 5.85],
        ["French", 1.66],
        ["Italian", 0.316],
        ["Romansh", 0.0791],
    ];

    const options = {
        legend: "none",
        pieSliceText: "label",
        pieStartAngle: 100,
    };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboardStat())
        .unwrap()
        .then(v => {
            console.log(v)
        })
    })
    return (
        <>
            <h1 className={GlobalStyles.pageTitle}>Dashboard</h1>
            <div className="general-informations mb-5 grid grid-cols-5 gap-4">
                <NumberIcon title="Users" number="2105" Icon={FiUsers} />
                <NumberIcon title="Lessons" number="16542" Icon={FiBookOpen} />
                <NumberIcon title="Income" number="14 205 €" Icon={FiDollarSign} />
                <NumberIcon title="Widthdrawn" number="9 852 €" Icon={FiCreditCard} />
                <NumberIcon title="Profit" number="6 497 €" Icon={FaMoneyBillTrendUp} />
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className="col-span-4 dashboardBox">
                    <h2 className='text-nt-blue m-5 mx-8'>Users</h2>
                    <BarChart data={userChartData} options={usersChartOptions} />
                </div>
                <div className="col-span-2 dashboardBox	">
                    <h2 className='text-nt-blue m-5 mx-8'>Fake data</h2>
                    <Chart
                        chartType="PieChart"
                        data={data}
                        options={options}
                        width={"100%"}
                        height={"400px"}
                    />
                </div>
            </div>
        </>
    )
}

export { DashboardAdmin }