import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Languages } from "../languagesInput";
import { FiArrowRight, FiPlusCircle } from "react-icons/fi";

import Styles from '../register.module.scss';
import { StepNavigation } from "../navigation";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillTutorLanguages } from "../../../../store/profile/profileActions";
import { TextInput, TextareaInput } from "../../../inputs";
import { Editor as OldEditor } from '@tinymce/tinymce-react';
import { getYoutubeID } from "../../../../utils";
import { setUser } from "../../../../store/auth/authSlice";
import Langs from '../../../../utils/data/langs.json'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TutorProfile = ({ setCurrentStep, setActiveTab }) => {
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const {
        fields: speaksFields,
        append: speaksAppend,
        remove: speaksRemove
    } = useFieldArray({
        control,
        name: "speak",
    });

    const {
        fields: teachFields,
        append: teachAppend,
        remove: teachRemove
    } = useFieldArray({
        control,
        name: "teach",
    });

    const onSubmit = async (data) => {
        setIsLoading(true);
        await dispatch(fillTutorLanguages({ ...data }))
            .then(() => {
                setIsLoading(false);
                dispatch(setUser(data.user))
                setCurrentStep(4);
            });
    }
    const description = watch("description")
    const teachValue = watch("teach");
    const speaksValue = watch("speak");
    const nextStep = () => {
        formRef.current.requestSubmit();
    }

    return (
        <form ref={formRef} id="tutor-profile" onSubmit={handleSubmit(onSubmit)} className={Styles.registerForm} style={{ textAlign: "center" }}>
            <h3 className="text-left">Languages taught</h3>

            {teachFields.map((field, index) => (
                <Languages
                    currentValue={teachValue}
                    key={field.id}
                    errors={errors}
                    control={control}
                    register={register}
                    id={field.id}
                    remove={teachRemove}
                    name="teach"
                    index={index}
                    required={true}
                />
            ))}


            {teachFields.length < 2 && <button
                type="button"
                className='inline m-auto'
                onClick={() => {
                    teachAppend({ lang: languages.filter((l) => !teachValue.map((v) => v.lang).includes(l.value))[0].value, level: "C1" });
                }}
            >
                <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
            </button>}

            <h3 className="text-left">Languages spoken</h3>

            {speaksFields.map((field, index) => (
                <Languages
                    currentValue={speaksValue}
                    key={field.id}
                    control={control}
                    register={register}
                    id={field.id}
                    remove={speaksRemove}
                    name="speak"
                    index={index}
                    isFirstSysLang={true}
                />
            ))}

            {speaksFields.length < 5 ? <button
                type="button"
                className='inline m-auto'
                onClick={() => {
                    speaksAppend({ lang: languages.filter((l) => !speaksValue.map((v) => v.lang).includes(l.value))[0].value, level: "C1" });
                }}
            >
                <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
            </button> : ''}
            <h3 className="text-left mb-5">About Me</h3>


            <Controller
                name="description"
                control={control}
                defaultValue={description}
                rules={{
                    required: 'This field is required',
                }}
                render={({ field: { onChange } }) => (
                    <>
                        <label className="block text-left text-primary-grey text-[13px] font-semibold pb-1">Description</label>
                        <OldEditor
                            apiKey='8u01dut30d4s6w8quh6x976ijj4ztfajnn32c51ywti8jmhe'
                            // onInit={(evt, editor) => editorRef.current = editor}
                            value={description}
                            onEditorChange={onChange}
                            init={{
                                height: 300,
                                branding: false,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                            }}
                        />
                        {/* <ReactQuill
                            theme={"snow"}
                            onChange={onChange}
                            value={description}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                    { 'indent': '-1' }, { 'indent': '+1' }],
                                    ['link'],
                                    [{'color': ['#000', "#EEE", "#DDD", "blue"]}],
                                ],
                                clipboard: {
                                    // toggle to add extra line breaks when pasting HTML:
                                    matchVisual: false,
                                },
                                
                            }}
                            formats={
                                [
                                    'header', 'font',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'color',
                                    'list', 'bullet', 'indent',
                                    'link'
                                ]
                            }
                            // bounds={'.app'}
                            placeholder={"Write something..."}
                        /> */}
                        {errors && errors["description"]?.type === "required" && (
                            <span className="error text-left">{errors["description"]?.message}</span>
                        )}
                    </>

                )}
            />


            <TextInput
                type="url"
                name="video"
                label="Youtube Video"
                errors={errors}
                register={register}
                style={{ marginTop: "20px" }}
                validationSchema={{
                    required: "Youtube video is required",
                    validate: value => {
                        if (getYoutubeID(value) !== false)
                            return true;
                        return 'Incorrect Youtube url format'
                    }
                }}
                required={false}
            />
            <StepNavigation moveBack={() => setCurrentStep(2)} moveForward={nextStep} isLoading={isLoading} />

        </form>
    )

}

export { TutorProfile }