import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
// import { Languages } from "../languagesInput";
import { FiArrowRight, FiChevronDown, FiChevronUp, FiPlusCircle } from "react-icons/fi";

import { CheckboxInput } from "../inputs/checkboxInput";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "../inputs";
import { Editor } from '@tinymce/tinymce-react';

import { editTutorLanguages, fillTutorLanguages } from "../../store/profile/profileActions";
import { getProfileTutor, getYoutubeID, successToast } from "../../utils";
import { Languages } from "../auth/register/languagesInput";
import Langs from "../../utils/data/langs.json"
import { LanguagesPrice } from "./languagePrice";

const TutorProfile = ({ setCurrentStep, setActiveTab }) => {
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const currentProfile = getProfileTutor()
    const [isOpen, setIsOpen] = useState(false)

    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            teach: currentProfile.teach,
            speak: currentProfile.speak,
            description: currentProfile.description,
            video: currentProfile.youtube,
            hideProfile: currentProfile.hideProfile || false,
        }
    });

    const {
        fields: speaksFields,
        append: speaksAppend,
        remove: speaksRemove
    } = useFieldArray({
        control,
        name: "speak",
    });

    const {
        fields: teachFields,
        append: teachAppend,
        remove: teachRemove
    } = useFieldArray({
        control,
        name: "teach",
    });

    const onSubmit = async (data) => {
        setIsLoading(true);
        console.log(data)
        await dispatch(editTutorLanguages({ ...data }))
            .then(() => {
                setIsLoading(false);
                successToast("Modification applied successfully")
            });
    }

    const description = watch("description")
    const teachValue = watch("teach");
    const speaksValue = watch("speak");

    return (
        <div className="rounded-2xl bg-white px-5 py-3 mb-5">
            <div onClick={() => setIsOpen(!isOpen)} className={`cursor-pointer flex items-center justify-between font-bold text-nt-blue border-color-nt-grey ${isOpen && "border-b-2 mb-4 pb-2 "}`}>
                Profile
                {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            <div className={isOpen ? "block" : "hidden"}>
                <form ref={formRef} id="tutor-profile" onSubmit={handleSubmit(onSubmit)} className={"bg-white p-4 rounded-xl"} style={{ textAlign: "center" }}>

                    <h3 className="text-left text-nt-blue">Global Settings</h3>
                    <CheckboxInput
                        name="hideProfile"
                        label="Hide my profile for now"
                        register={register}
                        control={control}
                        style={{ marginBottom: "20px", marginTop: "8px" }}

                    />
                    <h3 className="text-left text-nt-blue">Languages taught</h3>

                    {teachFields.map((field, index) => (
                        <LanguagesPrice
                            currentValue={teachValue}
                            key={field.id}
                            errors={errors}
                            control={control}
                            register={register}
                            id={field.id}
                            remove={teachRemove}
                            name="teach"
                            index={index}
                            required={true}
                            watch={watch}
                            length={teachFields.length}
                        />
                    ))}


                    {teachFields.length < 2 && <button
                        type="button"
                        className='inline m-auto'
                        onClick={() => {
                            teachAppend({ lang: languages.filter((l) => !teachValue.map((v) => v.lang).includes(l.value))[0].value, level: "C1" });
                        }}
                    >
                        <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
                    </button>}

                    <h3 className="text-left text-nt-blue">Languages spoken</h3>

                    {speaksFields.map((field, index) => (
                        <Languages
                            currentValue={speaksValue}
                            key={field.id}
                            control={control}
                            register={register}
                            id={field.id}
                            remove={speaksRemove}
                            name="speak"
                            index={index}
                            isFirstSysLang={true}
                            length={speaksFields.length}
                        />
                    ))}

                    {speaksFields.length < 5 ? <button
                        type="button"
                        className='inline m-auto'
                        onClick={() => {
                            speaksAppend({ lang: languages.filter((l) => !speaksValue.map((v) => v.lang).includes(l.value))[0].value, level: "C1" });
                        }}
                    >
                        <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
                    </button> : ''}
                    <h3 className="text-left mb-5 text-nt-blue">About Me</h3>


                    <Controller
                        name="description"
                        control={control}
                        defaultValue={description}
                        rules={{
                            required: 'This field is required',
                        }}
                        render={({ field: { onChange } }) => (
                            <>
                                <label className="block text-left text-primary-grey text-[13px] font-semibold pb-1">Description</label>
                                <Editor
                                    apiKey='8u01dut30d4s6w8quh6x976ijj4ztfajnn32c51ywti8jmhe'
                                    // onInit={(evt, editor) => editorRef.current = editor}
                                    value={description}
                                    onEditorChange={onChange}
                                    init={{
                                        height: 300,
                                        branding: false,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                                    }}
                                />
                                {errors && errors["description"]?.type === "required" && (
                                    <span className="error text-left">{errors["description"]?.message}</span>
                                )}
                            </>

                        )}
                    />


                    <TextInput
                        type="url"
                        name="video"
                        label="Youtube Video"
                        errors={errors}
                        register={register}
                        style={{ marginTop: "20px" }}
                        validationSchema={{
                            required: "Youtube video is required",
                            validate: value => {
                                if (getYoutubeID(value) !== false)
                                    return true;
                                return 'Incorrect Youtube url format'
                            }
                        }}
                        required={false}
                    />
                    <button className="rounded-xl px-5 py-2 bg-nt-blue text-white self-center mt-5">
                        Save
                    </button>
                </form>
            </div>
        </div>
    )

}

export { TutorProfile }