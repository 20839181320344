import { parseObjByString } from '../../utils';
import Styles from './inputs.module.scss';

const TextInput = ({ defaultValue = "", placeholder="", name, label, register, onChangeInput=null, errors, type, validationSchema, disabled, required, marginBottom = "20px", style={},inputClassname = "",inputStyle={}, minHtml, maxHtml }) => {

    return (<div className={`${Styles.textInput} ${disabled && Styles.disabled}`} style={{ marginBottom: marginBottom, ...style }}>
        <label htmlFor={name} className='block text-left text-primary-grey text-[13px] font-semibold pb-1'>
            {label}
            {validationSchema && validationSchema?.required ? " *" : ""}
        </label>
        <div className="relative text-left">
            <input
                className={'inputField w-full ' + inputClassname}
                id={name}
                name={name}
                placeholder={placeholder}
                required={required}
                defaultValue={defaultValue}
                type={type}
                {...register(name, validationSchema)}
                disabled={disabled}
                min={minHtml}
                max={maxHtml}
                style={{...inputStyle}}
            />
            {errors && (errors[name]?.type === "required" ||
                (parseObjByString(errors, name).length && parseObjByString(errors, name)[0]?.type === "required")
            ) && (
                    <span className="error">{errors[name] ? errors[name]?.message : parseObjByString(errors, name)[0]?.message}</span>
                )}
            {errors && errors[name]?.type === "minLength" && (
                <span className="error">{errors[name]?.message}</span>
            )}
            {errors && (errors[name]?.type === "validate" ||
                (parseObjByString(errors, name).length && parseObjByString(errors, name)[0]?.type === "validate")
            ) && (
                    <span className="error">{errors[name] ? errors[name]?.message : parseObjByString(errors, name)[0]?.message}</span>
                )}
        </div>
    </div>)
};

export { TextInput };