const ProfileWidget = ({user = null}) => {
    const blockUser = () => {
        console.log("dddd")
    }

    return (
        <>
            <div className="bg-white rounded-2xl p-4">
                <div className="flex justify-between items-start">

                    {user && <div className="flex items-start justify-start">
                        <img src="http://127.0.0.1:3420/api/v1/files\6df946aca27735f891267.png" className="rounded-full w-20 overflow-hidden object-cover" />
                        <div className="">
                            <div className="flex items-center gap-3">
                                <h4 className="capitalize">{user.firstName + " " + user.lastName}</h4>
                                {user.country ? <img src={`https://flagsapi.com/${user.country}/shiny/24.png`} /> : ''}
                            </div>
                            <div className="flex flex-col">
                                {user.phone ? <span>Phone: {user.phone}</span>: ''}
                                {user.email ? <span>Email: {user.email}</span>: ''}
                            </div>
                        </div>
                    </div>}
                    <div className="flex flex-col gap-4">
                        <span className="bg-nt-blue text-white px-2 py-1 rounded" onClick={() => blockUser()}>Active</span>
                        {/* <button className="bg-nt-red text-white px-2 py-1 rounded" onClick={() => blockUser()}>Block User</button> */}
                        {/* <button className="bg-nt-blue text-white px-2 py-1 rounded" onClick={() => blockUser()}>Unblock User</button> */}
                        {/* <span className="bg-nt-red text-white px-2 py-1 rounded">Blocked</span> */}
                    </div>

                </div>
            </div>
            
        </>
    )
}

export { ProfileWidget }