import { createSlice } from '@reduxjs/toolkit';


const initialState = {
};

const appointmentSlice = createSlice({
	name: 'appointment',
	initialState,
	reducers: {
	},
	extraReducers: {
	},
});

export default appointmentSlice.reducer;

// export const { setCredentials, setUser } = appointmentSlice.actions;
