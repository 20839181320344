import NotFoundImg from '../../assets/tutors/not-found.png'

const NotFoundWidget = ({message=""}) => {
    return(
        <div className='opacity-40 w-full select-none px-10 py-5 flex flex-col items-center content-center'>
            <img src={NotFoundImg} />
            <h3 className='text-nt-blue'>{message}</h3>
        </div>
    )
}

export default NotFoundWidget;