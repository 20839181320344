import toast from 'react-hot-toast';
import store from '../store';
import moment from 'moment';
import AvatarPlaceholder from '../assets/auth/register/avatar-placeholder.png'


export const successToast = (message) => {
	toast.success(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
	});
};

export const errorToast = (message) => {
	toast.error(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
	});
};

export const getImage = (path, type = "") => {
	if(!path && type == "AVATAR"){
		return AvatarPlaceholder
	}
	return process.env.REACT_APP_BACKEND_URL + path;
}

export const parseObjByString = (from, ...selectors) =>
	[...selectors].map(s =>
		s
			.replace(/\[([^\[\]]*)\]/g, '.$1.')
			.split('.')
			.filter(t => t !== '')
			.reduce((prev, cur) => prev && prev[cur], from)
	);

export const getYoutubeID = (url) => {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return (match && match[7].length == 11) ? match[7] : false;
}

export const getProfileAdmin = () => {
	const profiles = store.getState().profile.profiles;
	const isExist = profiles?.filter(v => v.role == 1).length > 0 ? profiles?.filter(v => v.role == 1) : [];

	if(isExist.length)
		return isExist[0];
	return false;
}

export const getProfileTutor = () => {
	const profiles = store.getState().profile.profiles;
	const isExist = profiles?.filter(v => v.role == 2).length > 0 ? profiles?.filter(v => v.role == 2) : [];

	if(isExist.length)
		return isExist[0];
	return false;
}

export const getProfileStudent = () => {
	const profiles = store.getState().profile.profiles;
	const isExist = profiles?.filter(v => v.role == 2).length > 0 ? profiles?.filter(v => v.role == 3) : [];

	if(isExist.length)
		return isExist[0];
	return false;
}

export const dateToServerFormat = (date, timezone) => {
	var tzNumber = timezone.replace("Etc/GMT", "")

	if(tzNumber == ""){
		tzNumber = "+00"
	}else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) < 0)
		tzNumber = "-0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) < 0)
		tzNumber = "-" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+" + Math.abs(parseInt(tzNumber))
	// console.log(date)
	// if(moment().add(1, "day") == date)
	// 	return moment(date).add(1, 'hour').format("YYYY-MM-DDTHH:")+ "00:00" + tzNumber + ":00"
	return moment(date).format("YYYY-MM-DDTHH:")+ "00:00" + tzNumber + ":00"
}

export const dateTimeToServerFormat = (date, timezone) => {
	var tzNumber = timezone.replace("Etc/GMT", "")

	if(tzNumber == ""){
		tzNumber = "+00"
	}else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) < 0)
		tzNumber = "-0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) < 0)
		tzNumber = "-" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+" + Math.abs(parseInt(tzNumber))
	// console.log(date)
	return moment(date).parseZone().format("YYYY-MM-DDTHH:00:00") + tzNumber + ":00"
}

export const dateToSlice = (date, withDate = false) => {
	date = moment(date);
	let timeStr = `${date.format("HH:mm")} - ${date.format("HH:50")}`
	// let dateStr = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
	// console.log("---", date)
	return withDate ? moment(date).format("YYYY-MM-DD ") + timeStr: timeStr;
}

export const getMomentDate = (date) => {
	return moment(date);
}

export const formatTimeZone = (timezone) => {
	var tzNumber = timezone.replace("Etc/GMT", "")
	if(tzNumber == ""){
		tzNumber = "+00"
	}else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) < 0)
		tzNumber = "-0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) < 0)
		tzNumber = "-" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+" + Math.abs(parseInt(tzNumber))

	return tzNumber + ":00"
}

export const formatDateToUserTZ = (date, timezone) => {
	var tzNumber = timezone.replace("Etc/GMT", "")
	if(tzNumber == ""){
		tzNumber = "+00"
	}else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) < 0)
		tzNumber = "-0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) < 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+0" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) < 0)
		tzNumber = "-" + Math.abs(parseInt(tzNumber))
	else if(Math.abs(parseInt(tzNumber)) >= 10 && parseInt(tzNumber) >= 0)
		tzNumber = "+" + Math.abs(parseInt(tzNumber))
	console.log(moment(date).tz(timezone).format());
	return moment(date).utcOffset(tzNumber + ":00").format("YYYY-MM-DD HH:mm");
}

export const getDeviceTimeZone = () => {
	console.log(moment().format("Z"))
	return moment().format("Z")
}

export const NTformatDate = (date) => {
	return moment(date).format("YYYY-MM-DDTHH:00:00Z")
}

export const getLocalDate = (date) => {
	return moment(date).format("YYYY-MM-DD HH:mm");
}
