import FullCalendar from '@fullcalendar/react'

import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarInfo } from '../../store/appointment/appointmentActions';
import { getDeviceTimeZone } from '../../utils';
import moment from 'moment';
import { RangeEventSelected } from './rangeEventSelected';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const CalendarView = () => {
    const cal = createRef();

    const [selectedKey, setSelectedKey] = useState(0);
    const [loading, setLoading] = useState(true)
    const [businessHours, setBusinessHours] = useState([]);
    const [events, setEvents] = useState([]);
    const dispatch = useDispatch();
    const { currentProfile } = useSelector((state) => state.profile)

    const [actionType, setActionType] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [minSlice, setMinSlice] = useState(0);
    const [maxSlice, setMaxSlice] = useState(24);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileToggled, setIsMobileToggled] = useState(false);

    const getCalendarData = (isRefresh = false) => {
        dispatch(getCalendarInfo({
            timezone: getDeviceTimeZone(),
            profile: currentProfile._id
        }))
            .unwrap()
            .then(v => {
                setBusinessHours(v.businessHours.days)
                setEvents(v.events)
                const maxMin = getMinMaxSlot(v.businessHours.days)
                setMinSlice(maxMin.min)
                setMaxSlice(maxMin.max)
                setLoading(false)
                if (isRefresh && cal.current) {
                    let calendarApi = cal.current.getApi();
                    calendarApi.unselect()
                }
            })

    }

    const getMinMaxSlot = (data) => {
        let minStart = Infinity;
        let maxEnd = -Infinity;

        data.forEach(day => {
            if (!day.off) {
                day.slices.forEach(slice => {
                    minStart = Math.min(minStart, slice.start);
                    maxEnd = Math.max(maxEnd, slice.end);
                });
            }
        });

        return {
            "min": minStart,
            "max": maxEnd
        }
    }
    useEffect(() => {
        getCalendarData()
    }, [])

    const selectAction = (e) => {
        // @To use if needed
        let calendarApi = cal.current.getApi();
        setIsOpen(true)
        setActionType("DISABLE_DATE")
        setCurrentEvent(e)
        setSelectedKey((x) => x + 1)
    }

    const handleEventMouseEnter = (arg) => {
        if (arg.event.extendedProps.disablePointerEvents) {
            arg.el.style.cursor = 'default';
        }
    };

    const handleEventMouseLeave = (arg) => {
        arg.el.style.cursor = '';
    };

    return (
        <>
            <h1 className="text-left text-nt-blue mb-5">Calendar</h1>
            <div className='flex gap-5'>
                <div className={`w-full ${isOpen ? 'xl:w-3/4' : 'xl:w-full'} bg-white rounded-xl p-5 mt-5`}>
                    {!loading && <FullCalendar
                        ref={cal}
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        allDaySlot={false}
                        eventOverlap={false}
                        headerToolbar={window.innerWidth > 767 ? {
                            left: 'prev,next',
                            center: 'title',
                            right: 'timeGridWeek,dayGridMonth'
                        } : {
                            left: 'prev,next',
                            right: 'title',
                        }}
                        visibleRange={{
                            start: new Date(),
                            end: null
                        }}
                        firstDay={1}
                        slotMinTime={(minSlice < 10 ? "0" + minSlice : minSlice) + ":00:00"}
                        slotMaxTime={(maxSlice < 10 ? "0" + maxSlice : maxSlice) + ":00:00"}
                        editable={false}
                        droppable={false}
                        timeZone={getDeviceTimeZone()}
                        contentHeight={maxSlice - minSlice > 12 ? 500 : (maxSlice - minSlice) * 40}
                        defaultTimedEventDuration={"00:50:00"}
                        handleWindowResize={true}
                        displayEventEnd={false}
                        slotLabelFormat={{
                            hour: '2-digit',
                            hour12: false,
                            minute: '2-digit',
                            meridiem: false
                        }}
                        eventTimeFormat={{
                            hour: '2-digit',
                            hour12: false,
                            minute: '2-digit',
                            meridiem: false
                        }}
                        events={events}
                        unselectAuto={false}
                        selectAllow={(selectInfo) => {
                            return new Date(selectInfo.start) >= moment()
                        }}
                        dayCellClassNames={(selectInfo) => {
                            return new Date(selectInfo.start) < new Date() ? "disabled" : ""
                        }}
                        select={(v) => selectAction(v)}
                        scrollTimeReset={false}
                        selectable={true}
                        slotDuration={'00:60:00'}
                        dragScroll={true}
                        slotLabelInterval={'00:10:00'}
                        businessHours={businessHours.map((v) => {
                            return [...v.slices.map((k) => ({
                                daysOfWeek: [v.day],
                                startTime: k.start < 10 ? "0" + k.start + ":00" : k.start + ":00",
                                endTime: k.end < 10 ? "0" + k.end + ":00" : k.end + ":00"
                            }))]
                        }).flat()}
                        eventMouseEnter={handleEventMouseEnter}
                        eventMouseLeave={handleEventMouseLeave}
                        eventClassNames={'pointer-events-none'}
                        validRange={() => {
                            if (cal.current) {
                                let calendarApi = cal.current.getApi();
                                var view = calendarApi.view;
                                if (view.type === 'dayGridMonth') {
                                    return {
                                        start: moment().utc().startOf("month").toISOString(),
                                        end: moment().utc().add(1, "year").endOf("month").toISOString()
                                    }
                                } else if (view.type === 'timeGridWeek') {
                                    return {
                                        start: moment().utc().startOf("week").toISOString(),
                                        end: moment().utc().add(1, "year").endOf("week").toISOString()
                                    }
                                }
                            } else {
                                return {
                                    start: moment().utc().startOf("week").toISOString(),
                                    end: moment().utc().add(1, "year").endOf("week").toISOString()
                                }
                            }
                        }}
                    />}
                </div>
                <div className={`hidden ${isOpen ? 'xl:block xl:w-1/4' : 'hidden'} bg-white rounded-xl p-5 mt-5`}>
                    {isOpen && actionType === "DISABLE_DATE" && <RangeEventSelected key={selectedKey} setIsOpen={(v) => setIsOpen(v)} refreshCalendar={() => getCalendarData(true)} currentEvent={currentEvent} events={events.filter((ev) => new Date(ev.date) >= currentEvent.start && new Date(ev.date) < currentEvent.end)} />}
                </div>
                <div className={`fixed xl:hidden overflow-y-auto z-50 bottom-0 md:w-[calc(100%-60px)] w-[calc(100%-40px)] ${isMobileToggled ? 'max-h-[75vh]' : 'max-h-24'}  ${isOpen ? 'block' : 'hidden'} bg-white rounded-xl p-5 mt-5`}>
                    {isOpen && actionType === "DISABLE_DATE" ? 
                        
                        (<>
                            
                            <RangeEventSelected 
                                isMobile={true}
                                isMobileToggled={isMobileToggled}
                                setIsMobileToggled={setIsMobileToggled}
                                key={selectedKey} 
                                setIsOpen={(v) => setIsOpen(v)} refreshCalendar={() => getCalendarData(true)} 
                                currentEvent={currentEvent} 
                                events={events.filter((ev) => new Date(ev.date) >= currentEvent.start && new Date(ev.date) < currentEvent.end)} 
                            />
                        </>)
                    : null}
                </div>
            </div>
        </>
    )
}

export { CalendarView }

// max-height: 100px;
//     overflow-y: scroll;
//     box-shadow: -2px -2px 10px #00000020, 3px 6px 10px #00000020;