import { Navigate } from 'react-router-dom';
import Styles from '../pages/home/home.module.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const FullWidth = ({ component: Component, isSwitch=false, ...props }) => {

    const { user, accessToken, refreshToken } = useSelector((state) => state.auth);
    const { currentProfile } = useSelector((state) => state.profile);

    const existProfile = () => {
        console.log("exist", currentProfile)
        return currentProfile && (
            (currentProfile.role == 2 && currentProfile.status == 1) ||
            (currentProfile.role == 3)
        )
    }
    
    return (
        <>
            {(!user && !accessToken) ? <Navigate to={{ pathname: '/login', state: { from: props.location } }} replace /> :
                (existProfile() && !isSwitch) ? <Navigate to={{ pathname: '/', state: { from: props.location } }} replace /> :
                    <div className={Styles.fullWidth}>
                        <Component {...props} />
                    </div>
            }
        </>
    );
};

export { FullWidth };
