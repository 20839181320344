import Styles from './inputs.module.scss';
import { useEffect, useRef, useState } from 'react';
import AvatarPlaceholder from '../../assets/auth/register/avatar-placeholder.png'
import { FiFile, FiUpload } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { parseObjByString } from '../../utils';
import { FieldError } from 'react-hook-form';
const FileInput = ({ name, label, scssClass, register, labelEnabled = false, acceptType = '', errors, required, type, validationSchema, control, style, defaultValue }) => {
    const hiddenFileInput = useRef(null);

    const { ref, ...rest } = register(name);
    const [preview, setPreview] = useState(false);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    
    const avatar = (preview && hiddenFileInput.current.files.length > 0) ?
        URL.createObjectURL(hiddenFileInput.current.files[0]) : (defaultValue != undefined && defaultValue.length > 0 && typeof defaultValue === "object") ?
            URL.createObjectURL(defaultValue[0]) : (defaultValue != undefined && defaultValue.length > 0 && !Array.isArray(defaultValue)) ?
            process.env.REACT_APP_BACKEND_URL + defaultValue:
            AvatarPlaceholder;
            
    const fileName = (preview && hiddenFileInput.current.files.length > 0) ?
        hiddenFileInput.current.files[0].name : (defaultValue != undefined && defaultValue.length > 0) ?
            defaultValue[0].name:
            false;

    return (
        <div className={`${Styles.fileInput} ${scssClass}`}>
            {labelEnabled && <label htmlFor={name} className='block text-left text-primary-grey text-[13px] font-semibold pb-1'>
                {label}
                {required && "*"}
            </label>}
            <div className="relative" style={{ width: "100%", height: "100%" }}>

                {style !== 'avatar' &&
                    <div
                        className="button-upload h-[100%] flex items-center justify-center"
                        onClick={() => handleClick()}
                    >
                        {fileName === false ?
                            <div>
                                <span><FiUpload /></span>
                                <p className='px-2'>Upload a file</p>
                            </div> :
                            <div>
                                <span><FiFile /></span>
                                <p className='px-2 truncate w-[180px]'>{fileName}</p>
                            </div>}
                    </div>
                }

                {style === 'avatar' &&
                    <div
                        className="button-upload avatar"
                    >
                        <img
                            onClick={() => handleClick()}
                            className={`${Styles.avatar} rounded-full w-[100px] h-[100px] object-cover`} src={avatar} />
                    </div>
                }

                <input
                    id={name}
                    name={name}
                    style={{ display: "None" }}
                    type="file"
                    accept={acceptType}
                    {...register(name, {
                        validate: {
                            'required': (data) => {
                                if ((data == null || data.length === 0) && required) {
                                    return "This Field is required";
                                }
                                return true
                            },
                            'size': (data) => {
                                if ((data != null && data.length != 0)) {
                                    console.log(data)
                                    if(data[0].size / 1024 > 5000)
                                        return "File size must be less than 5MB";
                                }
                                return true
                            }
                        },
                        key: { name },
                        onChange: (e) => {
                            setPreview(e.target.files.length > 0)
                        }
                    })} ref={(e) => {
                        ref(e)
                        hiddenFileInput.current = e
                    }} />


                {/* {errors && errors[name]?.type === "required" && (
                    <span className={style === "avatar" ? "text-center error" : "error"} >{errors[name]?.message}</span>
                )} */}
                {errors && (
                    errors[name]?.type === "required" ||
                    (parseObjByString(errors, name).length && parseObjByString(errors, name)[0]?.type === "required") ||
                    (parseObjByString(errors, name).length && parseObjByString(errors, name)[0]?.type === "size")
                ) && (
                        <span className={style === "avatar" ? "text-center error" : "error"} >{errors[name]?.message ? errors[name]?.message : parseObjByString(errors, name)[0]?.message}</span>
                    )}

                {errors && errors[name]?.type === "minLength" && (
                    <span className="error">{errors[name]?.message}</span>
                )}
            </div>
        </div>)
};
export { FileInput };