import { useDispatch, useSelector } from 'react-redux';
import noFiles from '../../assets/chat/no-files.png'
import SelectItemImg from '../../assets/chat/select-conversation.png'
import { useEffect, useState } from 'react';
import { getChatFiles } from '../../store/chat/chatActions';
import { getImage } from '../../utils';
import { FiDownload } from 'react-icons/fi';

const Files = () => {
    const foundFiles = false;
    const { currentChat, files } = useSelector((state) => state.chat);
    // const [files, setFiles] = useState([])
    const dispatch = useDispatch();


    const NoChatSelected = () => {
        return (
            <div className="text-center opacity-30">
                <img src={SelectItemImg} />
                <h3 className='text-nt-blue text-center'>Please select conversation</h3>
            </div>
        )
    }

    const NoSharedFilesSelected = () => {
        return (
            <div className="text-center opacity-30">
                <img src={noFiles} />
                <h3 className='text-nt-blue text-center'>No shared files in this conversation</h3>
            </div>
        )
    }
    // const files = currentChat.messages.filter((m) => m.isFile) 
    const FileList = () => {
        return (
            <div className='grow flex-1 h-full'>
            <h3 className='text-nt-blue mb-5'>File List</h3>
           
            {files.map((message) => {
                return <div key={message._id} c>
                      <a href={getImage(message.file.path)} 
                            className='mb-4 no-underline cursor-pointer rounded-xl flex justify-between bg-nt-grey-light text-nt-blue font-semibold py-3 w-full'>
                            <span className="px-2">{message.file.name}</span>
                            <FiDownload className="mx-2" size="20" />
                        </a>
                </div>
            })}
            </div>
        )
    }
    return (
        <>
            {currentChat === null && <NoChatSelected />}
            {(currentChat !== null && files.length === 0) && <NoSharedFilesSelected />}
            {(currentChat !== null && files.length > 0) && <FileList />}
        </>
    )

}

export { Files }