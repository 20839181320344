import Styles from '../../pages/users/users.module.scss'

const TablePagination = ({pageButtonCount, totalPages, pagination, pages}) => {
    return (<div className={Styles.pagination}>
        <div className={Styles.paginationInfos}>
            Total pages: {totalPages}
        </div>
        {totalPages > 0 ? <div className={Styles.paginationButtons}>
            <button
                type="button"
                disabled={pagination.state.page === 0}
                onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
            >
                {"<"}
            </button>
            {pages.map((_, index) => {
                console.log(pagination.state.page, pageButtonCount, index, totalPages)
                    if(
                        (pagination.state.page - index < pageButtonCount && pagination.state.page + pageButtonCount > index) ||
                        pagination.state.page === (totalPages - 1) && pagination.state.page - pageButtonCount <= index ||
                        pagination.state.page === 0 && index <= pageButtonCount
                    )
                        return (
                        <button
                        key={index}
                        type="button"
                        className={pagination.state.page === index ? Styles.active : ''}
                        onClick={() => pagination.fns.onSetPage(index)}
                        >
                        {index + 1}
                        </button>

                )})}
            <button
                type="button"
                onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                disabled={totalPages - 1 === pagination.state.page}
            >
                {">"}
            </button>
        </div> : 
        null}
    </div>)
}

export {TablePagination}