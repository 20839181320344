import { Link, useParams } from "react-router-dom"
import { SingleTutor } from "../../components/profile"
import Styles from './users.module.scss'
import { FiArrowLeft, FiBookOpen, FiCalendar, FiCreditCard, FiDollarSign, FiUsers } from "react-icons/fi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { AppointmentWidget, NumberIcon, ProfileWidget } from "../../components/widgets";
import { useEffect, useState } from "react";
import { getUserProfiles, getUserWithProfiles } from "../../store/profile/profileActions";
import { useDispatch } from "react-redux";
import { TutorTab } from "./tutorTab";
import { StudentTab } from "./studentTab";

const UserProfile = () => {
    const params = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [userProfiles, setUserProfiles] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(true)
    const [tutorProfile, setTutorProfile] = useState(null)
    const [studentProfile, setStudentProfile] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsLoading(true)
        dispatch(getUserWithProfiles({userId: params.userId}))
        .unwrap()
        .then((data) => {
            setUserProfiles(data.profiles);
            setUser(data.user)
            data.profiles?.map((v) => {
                if(v.role == 2)
                    setTutorProfile(v)
                else if(v.role == 3)
                    setStudentProfile(v)
            })
            
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            {!isLoading && <div className="flex bg-white place-content-between rounded-2xl p-4">
                <ul className={`${Styles.breadcumb} flex items-center gap-2`}>
                    <li className="flex">
                        <Link className="flex items-center no-underline gap-2 text-nt-grey" to={"/users/list"}>
                            <FiArrowLeft />Users
                        </Link>
                    </li>
                    <li>{user.firstName + " " + user.lastName}</li>
                </ul>
                <ul className="flex items-center gap-4">
                    <li onClick={() => setActiveTab(0)} className={`cursor-pointer rounded px-2 py-1 ${activeTab === 0 ? 'bg-nt-blue text-white': ''}`}>Profile</li>
                    {tutorProfile && <li onClick={() => setActiveTab(1)} className={`cursor-pointer rounded px-2 py-1 ${activeTab === 1 ? 'bg-nt-blue text-white': ''}`}>Tutor</li>}
                    {studentProfile && <li onClick={() => setActiveTab(2)} className={`cursor-pointer rounded px-2 py-1 ${activeTab === 2 ? 'bg-nt-blue text-white': ''}`}>Student</li>}
                </ul>
            </div>}

            <div className="mt-5">
                <div className="general-informations mb-5 grid grid-cols-5 gap-4">
                    <NumberIcon title="Attendance" number="80%" Icon={FiUsers} />
                    <NumberIcon title="Lessons" number="16542" Icon={FiBookOpen} />
                    <NumberIcon title="Appointment" number="48" Icon={FiCalendar} />
                    <NumberIcon title="Widthdrawn" number="9 852 €" Icon={FiCreditCard} />
                    <NumberIcon title="Profit" number="6 497 €" Icon={FaMoneyBillTrendUp} />
                </div>
            </div>

            <div className="mt-5">
                {activeTab === 0 && <ProfileWidget user={user}/>}
                {activeTab === 1 && !isLoading && <TutorTab profile={tutorProfile} />}
                {activeTab === 2 && !isLoading && <StudentTab profile={studentProfile} />}
            </div>
        </>
    )
}

export { UserProfile }