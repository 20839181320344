import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from "@table-library/react-table-library/pagination";

import Styles from './users.module.scss'
import { useState } from "react";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModerationList } from "../../store/profile/profileActions";
import PhoneInput from 'react-phone-input-2'
import countriesMask from "../../utils/data/phoneMasks.json"
import { getImage } from "../../utils";
import NotFoundWidget from '../../components/widgets/NotFound';
import { TablePagination } from '../../components/global/table-pagination';

const TutorsModerationList = () => {
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        dispatch(getModerationList())
        .unwrap()
        .then((v) => setNodes(v))
    },[])
    const capitalize = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join("");

    const COLUMNS = [
        {
            label: 'Avatar', class: "avatar", renderCell: (item) => {
                return <img className={Styles.avatar + " object-cover"} src={ getImage(item.userId.avatar?.path, "AVATAR")} />
            },
        },
        {
            label: 'Firstname',
            renderCell: (item) => capitalize(item.userId.firstName)
        },
        {
            label: 'Lastname',
            renderCell: (item) => capitalize(item.userId.lastName),
        },
        {
            label: 'Phone',
            renderCell: (item) => item.userId.phone.trim() != "" ?<PhoneInput
            disabled={true}
            masks={countriesMask}
            value={item.userId.phone}
            dropdownStyle= {{display: "none"}}
            inputStyle={{border:0, paddingLeft:0, cursor: "default"}}
            buttonClass="hidden"
            countryCodeEditable={false}
            onChange={phone => this.setState({ phone })}
          /> : ""
        },
        {
            label: 'Country', renderCell: (item) => {
                return <img src={`https://flagsapi.com/${item.userId.country}/shiny/24.png`} />
            },
        },
        {
            label: '',
            renderCell: (item, index) => {
                return <Link className={Styles.viewBtn} to={`/users/moderate/${item._id}`}>
                    <FiEye />
                    <span>Moderate</span>
                </Link>
            }
        },
    ];


    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            item.userId.firstName.toLowerCase().includes(search.toLowerCase()) ||
            item.userId.lastName.toLowerCase().includes(search.toLowerCase()) ||
            item.userId.phone?.toLowerCase().includes(search.toLowerCase())
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
    });

    const pageButtonCount = 2;
    const totalPages = pagination.state.getTotalPages(data.nodes)
    const pages = pagination.state.getPages(data.nodes);

    return (
        <div className={Styles.tableContainer}>
            <div className={Styles.tableHeader}>
                <h2>Moderation</h2>
                <label htmlFor="search">
                    <input id="search" type="text" placeholder="Search" className={Styles.search} value={search} onChange={handleSearch} />
                </label>
            </div>

            <CompactTable columns={COLUMNS} data={data} theme={theme} pagination={pagination} />
            {nodes.length === 0 ? <NotFoundWidget message="" /> : null}

            {totalPages > 0 ? <TablePagination
                pageButtonCount={pageButtonCount}
                totalPages={totalPages}
                pagination={pagination}
                pages={pages}
            /> : null}

        </div>
    )
}

export { TutorsModerationList }
