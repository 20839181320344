import { createSlice } from '@reduxjs/toolkit';
import { completeStudentProfile, completeTutorProfile, createStudent, createTutorProfile, deleteTutorCertificate, fillTutorCertificate, fillTutorExperience, fillTutorFormations, fillTutorInformations, getDashboardStat, getUserProfiles, switchToAdminProfile, switchToStudentProfile, switchToTutorProfile, tutorLastStep } from './profileActions'
import { setUser } from '../auth/authSlice';
import { useNavigate } from 'react-router';

const initialState = {
	loading: false,
	currentProfile: null,
	profiles: [],
	error: null,
	success: false,
	stats: {
		students: 0,
		lessons: 0,
		attendance: 0,
		profit: 0,
		pending: 0,
		pendinglessons: 0,
		spent: 0
	}
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile: (state, action) => {
			state.currentProfile = action.payload;
		},
		setProfiles: (state, action) => {
			state.profiles = action.payload;
		},
		resetProfile: (state) => {
			return initialState;
		},
	},
	extraReducers: {
		[getDashboardStat.fulfilled]: (state, action) => {
			state.stats.students = action.payload.students ? action.payload.students : 0;
			state.stats.lessons = action.payload.lessons ? action.payload.lessons : 0;
			state.stats.attendance = action.payload.attendance ? action.payload.attendance : 0;
			state.stats.profit = action.payload.profit ? action.payload.profit : 0;
			state.stats.pending = action.payload.pending ? action.payload.pending : 0;
			state.stats.spent = action.payload.spent ? action.payload.spent : 0;
			state.stats.pendinglessons = action.payload.pendinglessons ? action.payload.pendinglessons : 0;
		},
		[switchToAdminProfile.fulfilled]: (state, action) => {
			state.currentProfile = state.profiles.filter((v) => v.role === 1)[0]
		},
		[switchToStudentProfile.fulfilled]: (state, action) => {
			console.log(state.profiles)
			state.currentProfile = state.profiles.filter((v) => v.role === 3)[0]
		},
		[switchToTutorProfile.fulfilled]: (state, action) => {
			console.log(state.profiles)
			state.currentProfile = state.profiles.filter((v) => v.role === 2)[0]
		},
		[completeStudentProfile.fulfilled]: (state, action) => {
			state.currentProfile = { ...action.payload.profile, registred: false };
			state.profiles.push(action.payload.profile);
			console.log(action.payload.user)
		},
		
		[createTutorProfile.fulfilled]: (state, action) => {
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			if(profileIndex !== -1)
				state.profiles.push(action.payload);

		},
		[completeTutorProfile.fulfilled]: (state, action) => {
			state.currentProfile = { ...state.currentProfile, status: 0 };
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			state.profiles[profileIndex].status = 0
		},
		[fillTutorInformations.fulfilled]: (state, action) => {
			state.currentProfile = { ...action.payload.profile, status: 0 };
			const profileExist = state.profiles.filter(v => v._id == action.payload.profile._id).length > 0
			if (!profileExist)
				state.profiles.push(action.payload.profile);
		},
		[fillTutorFormations.rejected]: (state, action) => {
			console.log(state, action)
		},
		[fillTutorFormations.fulfilled]: (state, action) => {
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			state.profiles[profileIndex].noEducation = action.meta.arg.noEducation;
			if(!state.profiles[profileIndex].education)
				state.profiles[profileIndex].education = [] 
			if (action.payload.length)
				action.payload.map((v) => {
					state.profiles[profileIndex].education.push(v);
				})
			else if(action.meta.arg.noEducation)
				state.profiles[profileIndex].education = []
		},
		[fillTutorExperience.fulfilled]: (state, action) => {
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			state.profiles[profileIndex].noExperience = action.meta.arg.noExperience;
			if (action.payload.length){
				if(!state.profiles[profileIndex].experience)
				state.profiles[profileIndex].experience = []
				action.payload.map((v) => {
					state.profiles[profileIndex].experience.push(v);
				})
			}
				
			else if(action.meta.arg.noExperience)
				state.profiles[profileIndex].experience = []
		},
		[fillTutorCertificate.fulfilled]: (state, action) => {
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			state.profiles[profileIndex].noCertificate = action.meta.arg.noCertificate;
			if (action.payload.length){
				if(!state.profiles[profileIndex].certificate)
				state.profiles[profileIndex].certificate = []
				action.payload.map((v) => {
					state.profiles[profileIndex].certificate.push(v);
				})
			}
				
			else if(action.meta.arg.noCertificate)
				state.profiles[profileIndex].certificate = []
		},
		[deleteTutorCertificate.fulfilled]: (state, action) => {
			var profileIndex = state.profiles.findIndex((v) => v.role === 2);
			if (action.payload.type === "formations") {
				const elIndex = state.profiles[profileIndex].education.findIndex(v => v._id === action.payload.id)
				state.profiles[profileIndex].education.splice(elIndex, 1)
			}else if (action.payload.type === "experiences") {
				const elIndex = state.profiles[profileIndex].experience.findIndex(v => v._id === action.payload.id)
				state.profiles[profileIndex].experience.splice(elIndex, 1)
			}else if (action.payload.type === "certificates") {
				const elIndex = state.profiles[profileIndex].certificate.findIndex(v => v._id === action.payload.id)
				state.profiles[profileIndex].certificate.splice(elIndex, 1)
			}
		},
		[getUserProfiles.fulfilled]: (state, action) => {
			state.profiles = action.payload;
		},
		[tutorLastStep.fulfilled]: (state, action) => {
			state.currentProfile = action.payload;
			state.profiles[state.profiles.findIndex((v) => v.role === 2)] = action.payload;
		},
		[createStudent.fulfilled]: (state, action) => {
			state.currentProfile = action.payload;
			if(state.profiles.findIndex((v) => v.role === 3) === -1)
				state.profiles.push(action.payload);
		},
	},
});

export default profileSlice.reducer;

export const { setProfile, resetProfile, setProfiles } = profileSlice.actions;
