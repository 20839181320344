import { FiSearch } from 'react-icons/fi'
import Styles from './chat.module.scss'

const Search = ({searchText, handleChangeSearchText}) => {
    return (
        <div className={Styles.searchContainer + " mb-5"}>
            <FiSearch className={Styles.searchIcon} />
            <input key={"search_input"} type="text" placeholder="Search" value={searchText} onChange={(e) => handleChangeSearchText(e.target.value)} className={Styles.searchInput} />
        </div>
    )
}

export default Search