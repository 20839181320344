import { FiBookOpen, FiCreditCard} from 'react-icons/fi'
import { FaListCheck } from "react-icons/fa6";
import { NumberIcon } from '../../components/widgets'
import GlobalStyles from '../../utils/sass/global.module.scss'
import { Messages } from '../../components/chat';
import { Calendar } from '../appointment/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStat } from '../../store/profile/profileActions';
import { useEffect } from 'react';

const DashboardStudent = () => {
    const {stats} = useSelector(state => state.profile)    
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDashboardStat())
    },[])
    
    const {pending} = useSelector(state => state.wallet)

    return (
        <>
            <h1 className={GlobalStyles.pageTitle}>Dashboard</h1>
            <div className="general-informations mb-5 grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
                <NumberIcon title="Lessons" number={stats.lessons} Icon={FiBookOpen} />
                <NumberIcon title="Next lessons" number={stats.pendinglessons} Icon={FiBookOpen} />
                <NumberIcon title="Attendance" number="100%" Icon={FaListCheck} />
                <NumberIcon title="Pending Payment" number={pending+ " €"} Icon={FiCreditCard} />
            </div>
            
            <div className="grid lg:grid-cols-6 gap-4">
                <div className="grid col-span-1 lg:col-span-4 bg-white p-5 rounded-xl">
                    <h2 className='text-nt-blue max-h-[50px]'>Appointments</h2>
                    <Calendar hideTitle={true} />
                </div>
                <div className="flex-col col-span-1 lg:col-span-2 bg-white p-5 flex items-start justify-start rounded-xl">
                    <h2 className='text-nt-blue max-h-[50px]'>Chat</h2>
                    <Messages getMessage={true} search={false} />
                </div>
            </div>
        </>
    )
}

export { DashboardStudent }