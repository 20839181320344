import { FormProvider, useForm } from "react-hook-form";
import { DaysAvailability } from "../settings/daysAvaibility"
import { Tarif } from "../settings/tarif"
import { useDispatch, useSelector } from "react-redux";
import { tutorLastStep } from "../../store/profile/profileActions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const CompleteTutorProfile = () => {
    const dispatch = useDispatch();
    const { currentProfile } = useSelector((state) => state.profile);
    const [langs, setLangs] = useState([])
    useEffect(()=> {
        if(currentProfile && currentProfile.teach && currentProfile.teach.length)
            setLangs(currentProfile.teach.map((v) => v.lang))
    }, [])

    const methods = useForm({
        defaultValues: {
            days: [
                {
                    day: 0,
                    name: "Sunday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 1,
                    name: "Monday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 2,
                    name: "Tuesday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 3,
                    name: "Wednesday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 4,
                    name: "Thursday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 5,
                    name: "Friday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    day: 6,
                    name: "Saturday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
            ],
            prices: [...((currentProfile && currentProfile.teach )? currentProfile.teach.map((v) => v.lang).map((v) => {
                return {
                    abbr: v,
                    price: "",
                    priceFive: "",
                    priceTen: ""
                }
            }): [])]
           

        }
    });
    const navigate = useNavigate()
    useEffect(() => {
        if(currentProfile.lastStep) navigate("/")
    }, [currentProfile.lastStep])

    const onSubmit = async (data) => {
        console.log(data);
        // return;
        dispatch(tutorLastStep(data))
            .unwrap()
            .then(v => console.log(v))
    };

    return (
        <div className="">
            

            <form onSubmit={methods.handleSubmit(onSubmit)} className="bg-white p-8 rounded-2xl flex flex-col">
                <FormProvider {...methods} className>
                <h2 className="text-nt-blue mb-10 text-center">Last step to activate your profile</h2>
                    <div className="flex items-center xl:items-start flex-col xl:flex-row gap-10" >
                        <DaysAvailability {...methods} />
                        <Tarif />
                    </div>
                </FormProvider >
                <input className="self-end bg-nt-blue text-white cursor-pointer rounded p-2 mt-5" type="submit" value={"Submit"} />
            </form>
        </div>

    )
}

export { CompleteTutorProfile }