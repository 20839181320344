import { createSlice } from '@reduxjs/toolkit';
import {
	loginUser, logoutUser, registerUser, confirmUser, verifyUserDetails, completeStudentProfile, updateUser,
	getCurrentUser
} from './authActions';
import profileReducer from '../profile/profileSlice';

const userRefreshToken = localStorage.getItem('userRefreshToken')
	? localStorage.getItem('userRefreshToken')
	: null;

const userAccessToken = localStorage.getItem('userAccessToken')
	? localStorage.getItem('userAccessToken')
	: null;
	
const initialState = {
	loading: false,
	user: null,
	accessToken: userAccessToken,
	refreshToken: userRefreshToken,
	error: null,
	success: false,
	profile: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			console.log("abcd")
			state.user = action.payload.user;
			state.profile = action.payload.profile;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			localStorage.setItem('userAccessToken', action.payload.accessToken);
			localStorage.setItem('userRefreshToken', action.payload.refreshToken);
		},
		setUser: (state, action) => {
			state.user = {...state.user, ...action.payload}
		}
	},
	extraReducers: {
		[registerUser.pending]: (state) => {
			state.loading = true;
		},
		[registerUser.fulfilled]: (state, action) => {
			state.loading = false;
			console.log(action.payload)
			state.user = action.payload;
			state.error = null;
			state.success = true;
		},
		[registerUser.rejected]: (state, action) => {
			state.loading = false;
			state.success = false;
			state.error = action.payload.error;
		},
		[confirmUser.pending]: (state) => {
			state.loading = true;
		},
		[confirmUser.fulfilled]: (state, action) => {
			state.loading = false;
			console.log(action.payload)
			state.user = action.payload.user;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			localStorage.setItem('userAccessToken', action.payload.access_token);
			localStorage.setItem('userRefreshToken', action.payload.refreshToken);
			state.error = null;
			state.success = true;
		},
		[confirmUser.rejected]: (state, action) => {
			state.loading = false;
			state.success = false;
			console.log(action.payload)
			state.error = action.payload.error;
		},
		[completeStudentProfile.pending]: (state) => {
			state.loading = true;
		},
		[completeStudentProfile.fulfilled]: (state, action) => {
			state.loading = false;
			console.log("first user", action.payload);
			state.profile = action.payload;
			state.error = null;
			state.success = true;
		},
		[completeStudentProfile.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload.error;
		},

		[loginUser.pending]: (state) => {
			state.loading = true;
		},
		[loginUser.fulfilled]: (state, action) => {
			state.loading = false;
			state.user = action.payload.user;
			state.accessToken = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
			localStorage.setItem('userAccessToken', action.payload.token);
			localStorage.setItem('userRefreshToken', action.payload.refreshToken);
			state.error = null;
		},
		[loginUser.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload.error;
		},

		[logoutUser.pending]: (state) => {
			state.loading = true;
		},
		[logoutUser.fulfilled]: (state) => {
			state.loading = false;
			state.user = null;
			state.accessToken = null;
			localStorage.removeItem('userAccessToken');
			localStorage.removeItem('userRefreshToken');
			state.success = true;
			state.error = null;
		},
		[logoutUser.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload.error;
		},

		[verifyUserDetails.pending]: (state) => {
			state.loading = true;
		},
		[verifyUserDetails.fulfilled]: (state, action) => {
			state.loading = false;
			state.user = action.payload.user;
			state.accessToken = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
			localStorage.setItem('userAccessToken', action.payload.token);
			localStorage.setItem('userRefreshToken', action.payload.refreshToken);
			state.error = null;
			console.log("action.payload.profiles", action.payload.profiles)
			// if(action.payload.profiles && action.payload.profiles.length > 0)
			// 	profileReducer.setProfiles(state, action.payload.profiles)
			
		},
		[verifyUserDetails.rejected]: (state) => {
			state.loading = false;
		},
		[updateUser.fulfilled]: (state, action) => {
			state.user = action.payload;
		},
		// [getCurrentUser.fulfilled]: (state, action) => {
		// 	state.user = action.payload;
		// },
	},
});

export default authSlice.reducer;

export const { setCredentials, setUser } = authSlice.actions;
