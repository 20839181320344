import { useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useFieldArray, useForm } from 'react-hook-form';
import { FiChevronUp, FiChevronsLeft, FiChevronsUp, FiTrash } from 'react-icons/fi';
import { SelectInput } from '../inputs';
import Langs from '../../utils/data/langs.json'
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, getDayAvailability } from '../../store/appointment/appointmentActions';
import { dateToSlice, getMomentDate, NTformatDate } from '../../utils';
import moment from 'moment';
import { useNavigate } from 'react-router';

const AppointmentBox = ({ profile }) => {
    const [value, onChange] = useState(moment().parseZone().add(1, "day").add(1, "hour").toDate());
    const [availableAppointments, setAvailableAppointments] = useState([])
    const [isSlicesLoading, setIsSlicesLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.profile);
    const [mobBoxOpen, setMobBoxOpen] = useState(false)
    useEffect(() => {

        setIsSlicesLoading(true)
        console.log(value)
        dispatch(getDayAvailability({
            profile: profile._id,
            day: NTformatDate(value)
        }))
            .unwrap()
            .then(v => {
                const appointments = getValues("appointments");
                let slices = v.map((slice => {
                    console.log(slice.date, moment(slice.date).utc().local().toString())
                    return {
                        date: moment(slice.date),
                        string: dateToSlice(slice.date),
                        disabled: appointments.findIndex(k => moment(slice.date).diff(k.date) === 0) !== -1
                    };
                }))
                setAvailableAppointments(slices)
                setIsSlicesLoading(false)
            })
    }, [value])

    const appendDate = (slice) => {
        appointmentsAppend({ date: slice.date, text: dateToSlice(slice.date, true) })
        setAvailableAppointments((v) => v.map(appointment => { return appointment.date == slice.date ? { ...appointment, disabled: true } : appointment }))
    }

    const SessionTag = ({ slice, index }) => {
        return <div onClick={() => appendDate(slice)} className='bg-nt-grey text-white px-2 py-1 rounded cursor-pointer select-none'>
            {slice.string}
        </div>
    }

    let canTeach = profile.teach.map((v) => v.lang)
    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name } }).filter((l) => canTeach.includes(l.value))

    const { control, errors, watch, register, getValues } = useForm({
        defaultValues: {
            language: canTeach[0]
        }
    });

    const {
        fields: appointmentsFields,
        append: appointmentsAppend,
        remove: appointmentsRemove
    } = useFieldArray({
        control,
        name: "appointments",
    });

    const removeAppointment = (slice, i) => {
        setAvailableAppointments((v) => v.map(appointment => { return moment(appointment.date).diff(slice.date) === 0 ? { ...appointment, disabled: false } : appointment }))
        appointmentsRemove(i)
    }
    const curLang = watch("language")

    const checkout = () => {
        setIsLoading(true)
        const selected = getValues("appointments");
        const data = selected.map((v => {
            console.log(v.date, moment(v.date).utc().toString())
            return {
                tutor: profile._id,
                student: currentProfile._id,
                date: moment(v.date).toISOString(),
            }
        }))

        dispatch(createOrder({ events: data, tutor: profile._id, student: currentProfile._id, lang: getValues("language") }))
            .unwrap()
            .then(v => {
                window.location.href = v
                setIsLoading(false)
            })
    }
    const { balance } = useSelector(state => state.wallet)
    const Tarif = () => {
        return <div className='flex w-full sm:w-auto flex-col sm:flex-row gap-1 sm:gap-5 mt-5'>
            <p>{profile.teach.filter((v) => v.lang == curLang)[0].price}€ / Course</p> 
            <p>{profile.teach.filter((v) => v.lang == curLang)[0].priceFive}€ / 5+ Courses</p> 
            <p>{profile.teach.filter((v) => v.lang == curLang)[0].priceTen}€ / 10+ Courses</p>
        </div>
    }
    return (
        <div className="p-5 rounded-xl bg-white mt-5 mb-20 md:mb-2">
            <div className='flex justify-between items-center flex-col md:flex-row mb-5'>
                <h3 className='text-nt-blue w-full'>Appointment booking</h3>
                <SelectInput
                    name={`language`}
                    label=""
                    errors={errors}
                    register={register}
                    control={control}
                    options={languages}
                    isSearchable={true}
                    onChangeSelect={event => {
                        console.log(event)
                        // field.onChange({ ...field.value, lang: event.value })
                    }}
                />
                <div className='md:hidden w-full'>
                    <Tarif />
                </div>
            </div>
            <div className='flex gap-4 items-start flex-col md:flex-row '>
                <div className='w-full md:w-2/6'>
                    
                    <h5 className='mb-4 text-nt-blue'>Choose a date</h5>
                    <Calendar className={"nt-calendar "}
                        onChange={onChange}
                        view='month'
                        // tileDisabled={({ activeStartDate, date, view }) => date.getDay() === 0 || date.getDay() === 1}
                        minDate={moment().parseZone().add(1, "day").add(1, "hour").toDate()}
                        maxDate={moment().parseZone().add(1, "year").add(1, "hour").toDate()}
                        value={value} />
                </div>
                <div className='w-full md:w-2/6'>
                    <h5 className='mb-4 text-nt-blue'>Pick appointments</h5>
                    <div className='flex gap-2 items-start justify-start flex-wrap'>
                        {
                            isSlicesLoading ? "Loading" :
                                availableAppointments.length > 0 ? availableAppointments.map((v, i) => {
                                    if (!v.disabled)
                                        return <SessionTag slice={v} index={i} key={i} />
                                }) : "No available Slices"
                        }
                    </div>
                    
                </div>
                <div className='hidden md:block w-full md:w-2/6'>
                    <h5 className='mb-4 text-nt-blue'>Selected appointments</h5>
                    <div className='flex flex-col justify-between md:min-h-[280px]'>
                        <div className='flex gap-2 items-start justify-start flex-wrap overflow-y-auto max-h-[250px] '>
                            {appointmentsFields.map((v, i) => {

                                return <div className='flex items-center gap-2 bg-nt-blue text-white px-2 py-1 rounded cursor-pointer' key={i}>
                                    {dateToSlice(v.date, true)} <span onClick={() => removeAppointment(v, i)}><FiTrash /></span>
                                </div>
                            })}
                        </div>
                        <div className='flex items-end gap-5 mt-4'>

                            <div className='flex items-end gap-2'>
                                <h5>Balance: </h5>
                                <p>{balance} €</p>
                            </div>
                            <div className='flex items-end gap-2'>
                                <h4>Total:</h4>
                                <p>{appointmentsFields.length >= 10 ? profile.teach.filter((v) => v.lang == curLang)[0].priceTen * appointmentsFields.length :
                                    appointmentsFields.length >= 5 ? profile.teach.filter((v) => v.lang == curLang)[0].priceFive * appointmentsFields.length :
                                        profile.teach.filter((v) => v.lang == curLang)[0].price * appointmentsFields.length} €
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='hidden md:flex justify-between'>
                <Tarif />
                <div className='w-2/6'>
                    <button disabled={appointmentsFields.length === 0 || isLoading} onClick={() => checkout()} className='w-full bg-nt-blue text-white py-2 rounded'>
                        {isLoading ? "Loading" : "Checkout"}
                    </button>
                </div>
            </div>

            <div className='md:hidden w-full md:w-2/6 fixed bottom-0 bg-nt-blue rounded-t-xl p-2 text-white left-0 z-50'>
                <div onClick={() => setMobBoxOpen(x => !x)}>
                    <FiChevronUp className='absolute right-2' size={24} />
                </div>
                    <h5 className='mb-5'>{appointmentsFields.length} Selected appointments</h5>
                    <div className= {`flex flex-col h-full ${!mobBoxOpen ? "h-6" : "h-[80vh]"} transition-transform ease-linear duration-200`}>
                        <div className='flex flex-col items-start gap-2 overflow-y-auto max-h-[60vh] flex-1'>
                            {appointmentsFields.map((v, i) => {

                                return <div className='flex items-center gap-2 bg-white text-nt-blue px-2 py-1 rounded cursor-pointer' key={i}>
                                    {dateToSlice(v.date, true)} <span onClick={() => removeAppointment(v, i)}><FiTrash /></span>
                                </div>
                            })}
                        </div>
                        {mobBoxOpen === true && <div className='w-full self-end'>
                            <button disabled={appointmentsFields.length === 0 || isLoading} onClick={() => checkout()} className='w-full bg-white text-nt-blue mb-5 py-2 rounded'>
                                {isLoading ? "Loading" : "Checkout"}
                            </button>
                        </div>}
                        <div className='flex items-end gap-5'>

                            <div className='flex items-end gap-2'>
                                <h5>Balance: </h5>
                                <p>{balance} €</p>
                            </div>
                            <div className='flex items-end gap-2'>
                                <h4>Total:</h4>
                                <p>{appointmentsFields.length >= 10 ? profile.teach.filter((v) => v.lang == curLang)[0].priceTen * appointmentsFields.length :
                                    appointmentsFields.length >= 5 ? profile.teach.filter((v) => v.lang == curLang)[0].priceFive * appointmentsFields.length :
                                        profile.teach.filter((v) => v.lang == curLang)[0].price * appointmentsFields.length} €
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export { AppointmentBox }