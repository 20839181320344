import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { NavbarAdmin, NavbarStudent, NavbarTutor } from '../navbar';
import Styles from '../../pages/home/home.module.scss';
import { FiHeadphones } from 'react-icons/fi';
import { goToSupport } from '../../store/chat/chatActions';

const PrivateRoute = ({ component: Component, ...props }) => {
	const { user } = useSelector((state) => state.auth);
	const { currentProfile } = useSelector((state) => state.profile);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleSupport = () => {
		console.log(location.pathname)
		if(!location.pathname.includes("/chat")){
			dispatch(goToSupport())
			.unwrap()
			.then((v) => navigate("/chat/" + v._id))
		}
	}
	const Support = () => {
		return <div className='fixed bottom-2 right-2 flex items-center justify-center rounded-full z-50 bg-white w-10 h-10 cursor-pointer' onClick={() => handleSupport()}><FiHeadphones size={20} /></div>
	}
	const RedirectTo = () => {
		navigate("/laststep")
		window.location.href= "/laststep"
	}
	console.log(currentProfile && currentProfile.role == 2 && !currentProfile.lastStep && location.pathname != "/laststep", !user)
	return (
		<>
			{
				(!user || !user.confirmed)
					? <Navigate to={{ pathname: '/login', state: { from: props.location } }} replace />
					:
					(currentProfile && currentProfile.role == 2 && !currentProfile.lastStep && location.pathname != "/laststep") ?
					<><RedirectTo /></>
						
						:
						<div className={Styles.mainContainer}>
							{currentProfile != undefined && currentProfile.role === 1 && <NavbarAdmin />}
							{currentProfile != undefined && currentProfile.role === 2 && <NavbarTutor />}
							{currentProfile != undefined && currentProfile.role === 3 && <NavbarStudent />}


							<div className={Styles.pageWrap}>
								{/* <div className='bg-white h-10 -z-1 w-screen absolute left-0 top-0'>
								notifi
							</div> */}
								<Component {...props} />
								{!location.pathname.includes('/tutor/') && <Support />}
							</div>
						</div>
			}
		</>
	);
};

export { PrivateRoute };
