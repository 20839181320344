import { FiHeadphones, FiSearch } from 'react-icons/fi'
import Styles from './chat.module.scss'
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConversationList, getFullChat } from '../../store/chat/chatActions';
import { getImage } from '../../utils';
import NotFoundWidget from '../widgets/NotFound';
import { SocketContext } from '../../utils/socketConfig';
import Search from './seach-input';

const Messages = ({ getMessage = false, search = true, defaultChat = null }) => {

    const dispatch = useDispatch();
    const { currentChat, chats } = useSelector((state) => state.chat)
    const [searchText, setSearchText] = useState("")
    const socket = useContext(SocketContext);
    
    useEffect(() => {
        if (getMessage) {
            dispatch(getConversationList())
        }
    }, [])
    
    useEffect(() => {
        if(socket !== null)
            socket.on("message", (v) => dispatch(getConversationList()))
    }, [socket])

    useEffect(() => {
        if (defaultChat) {
            handleReadMessage(defaultChat)
        }

    }, [defaultChat])

    const handleReadMessage = (id) => {
        dispatch(getFullChat(id))
    }

    const handleChangeSearchText = (value) => {
        setSearchText(value)
    }

    // const Search = () => {
    //     return (
    //         <div className={Styles.searchContainer + " mb-5"}>
    //             <FiSearch className={Styles.searchIcon} />
    //             <input key={"search_input"} type="text" placeholder="Search" value={searchText} onChange={(e) => handleChangeSearchText(e.target.value)} className={Styles.searchInput} />
    //         </div>
    //     )
    // }

    const MessagesItem = ({ chat }) => {
        const isSelected = (currentChat !== null ? chat._id === currentChat._id : false);

        return (
            <div onClick={() => (!isSelected && !getMessage) && handleReadMessage(chat._id)} className={`${Styles.messageContainer} ${isSelected ? Styles.selected : ''}`}>
                {chat.support === true ? <span className={`h-[50px] w-[50px] ${isSelected ? 'bg-white' : 'bg-nt-grey-light'} rounded-full flex items-center justify-center text-nt-blue`}><FiHeadphones size={30} /></span> : <img className={Styles.avatar+ " rounded-full object-cover"} src={getImage(chat.user.avatar, "AVATAR")} />}
                <div className={Styles.content}>
                    <div className="capitalize">
                        {chat.support === true ? "Support" : chat.user.firstName + " " + chat.user.lastName}
                    </div>
                    {chat.message ? <div className={Styles.lastMessage}>
                        {chat.message.isFile ? "File Message" : chat.message.message}
                    </div> : ''}
                </div>
            </div>
        )
    }

    const chatsList = chats.filter(
        (v) =>
            (!v.user && "Support".includes(searchText) && v.support) ||
            (v.user && (v.user.firstName + " " + v.user.lastName).includes(searchText))

    )

    return (
        <>
            {search && <Search handleChangeSearchText={handleChangeSearchText} searchText={searchText} key={"search-input"}/>}
            <div className={`${Styles.messageList} w-full bg-white rounded-xl`}>
                {
                    chatsList.length > 0 ?
                        (chatsList.map((chat, i) => {
                            return <MessagesItem chat={chat} key={i} />
                        })) :
                        <NotFoundWidget message="No Chat History" />
                }
                { }
            </div>
        </>
    )
}

export { Messages }