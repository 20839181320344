import { useDispatch, } from 'react-redux';
import {
    FiArrowRight,
    FiArrowLeft
} from 'react-icons/fi';
import AnimatedTextWord from '../../../components/animation/animatedTextWord';
import Styles from './register.module.scss'
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';

const RightText = () => {
    const [indexMsg, setIndexMsg] = useState(0);

    const messages = [
        "Welcome to Nativtalk, where learning a language is an exciting journey",
        "Our platform is designed to make your language learning experience engaging.",
        "Our user-friendly interface and dynamic resources adapt to all learning styles and levels.",
    ]

    useEffect(() => {
		var i = 0;
		const intervalID = setInterval(() => {
			setIndexMsg(i)
			i = (i + 1) % messages.length;
		}, 5000);

		return () => clearInterval(intervalID);
	}, [indexMsg]);
    

    return useMemo(() =>
        <div>
            {indexMsg % 2 === 0 && <AnimatedTextWord text={messages[indexMsg]} />}
            {indexMsg % 2 === 1 && <AnimatedTextWord text={messages[indexMsg]} />}
        </div>
    );
};
export { RightText };
