import { useSelector } from "react-redux";
import { TutorSettings } from "./tutorSettings";
import { UserSettings } from "./userSettings";
import { Link } from "react-router-dom";

const Settings = () => {
    const { currentProfile } = useSelector((state) => state.profile);
    const { user } = useSelector((state) => state.auth);
    const role = currentProfile?.role;
    return (
        <>
            {(role === 3  || role === 1)&& <div>
                <h1 className="text-left text-nt-blue mb-8">User settings</h1>      
                <UserSettings />
            </div>}

            {(role === 2) && <div>
                <div className="flex justify-between items-center">
                    <h1 className="text-left text-nt-blue mb-8">Settings</h1>      
                    <Link to={"/me"} target="_blank"  className="bg-nt-blue text-white rounded py-2 mt-1 no-underline px-3">Profile Preview</Link>
                </div>
                <TutorSettings />
            </div>}
            
        </>
    )
}

export {Settings};