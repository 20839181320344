import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceTimeZone } from "../../../utils"
import { disableRange } from "../../../store/appointment/appointmentActions";
import { Controller, useForm, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

const WorkHoursCancel = ({ setIsCancel, currentEvent, events, setIsOpen }) => {
    const dispatch = useDispatch();
    const { control, register } = useFormContext()

    
    const handleCancelRange = (data) => {
        const userTz = getDeviceTimeZone()

        dispatch(disableRange({
            reason: data.reason.label,
            start: moment(currentEvent.startStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            end: moment(currentEvent.endStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            action: "cancel",
            events: events.map((v) => v._id)
        }))
            .unwrap()
            .then((v) => {
                setIsCancel(false)
                setIsOpen(false)
            })
    }

    const IssueOptions = [
        { value: 1, label: "Health issues" },
        { value: 2, label: "I am taking leave" },
        { value: 3, label: "I wish to change my rates" },
        { value: 4, label: "I have another commitment" },
        { value: 5, label: "I have a technical problem" },
        { value: 6, label: "Other" },
    ]

    return <div>
        <div className="flex flex-col justify-center gap-5 mt-5">
            <h4 className="text-nt-blue text-center">Are you sure to cancel this courses?</h4>
            <Controller
                control={control}
                name="reason"
                render={({ field: { onChange, value } }) => (
                    <ReactSelect
                        className="w-full"
                        placeholder="Reason"
                        required={true}
                        onChange={onChange}
                        value={value || ''}
                        options={IssueOptions}
                    />
                )}
            />
            <input name="isCancel" {...register("isCancel")} type="hidden" defaultValue={1}  />
            <button className="bg-nt-red text-white px-2 py-3 rounded w-full">Yes</button>
            <button type="button" className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => setIsCancel(false)}>No</button>
        </div>
    </div>
}
export { WorkHoursCancel }