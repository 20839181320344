import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceTimeZone } from "../../utils";
import { disableRange } from "../../store/appointment/appointmentActions";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";

const CancelRangeEvent = ({ setIsCancel, currentEvent, events, refreshParent, setIsDisabling, setIsOpen }) => {
    const dispatch = useDispatch();
    const { handleSubmit, control, watch, register, setValue } = useForm()


    const handleCancelRange = (data) => {
        const userTz = getDeviceTimeZone()

        dispatch(disableRange({
            reason: data.reason.label,
            start: moment(currentEvent.startStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            end: moment(currentEvent.endStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            action: "cancel",
            events: events.map((v) => v._id)
        }))
            .unwrap()
            .then((v) => {
                setIsDisabling(false);
                setIsCancel(false)
                setIsOpen(false)
                refreshParent()
            })
    }

    const { currentProfile } = useSelector(state => state.profile)
    const IssueOptions = [
        ...(currentProfile.role == 2 ? [
            { value: 1, label: "Health issues" },
            { value: 2, label: "I am taking leave" },
            { value: 3, label: "I wish to change my rates" },
            { value: 4, label: "I have another commitment" },
            { value: 5, label: "I have a technical problem" },
            { value: 6, label: "Other" },
        ]
            : currentProfile.role == 3 ?
                [
                    { value: 1, label: "Health issues" },
                    { value: 4, label: "I have another commitment" },
                    { value: 5, label: "I have a technical problem" },
                    { value: 6, label: "Other" },
                ]
                : []
        )
    ]

    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleCancelRange(data))}>
            <div className="flex flex-col justify-center gap-5 mt-5">
                <h4 className="text-nt-blue text-center">Are you sure to cancel this courses?</h4>
                <Controller
                    control={control}
                    name="reason"
                    render={({ field: { onChange, value } }) => (
                        <ReactSelect
                            className="w-full"
                            placeholder="Reason"
                            required={true}
                            onChange={onChange}
                            value={value || ''}
                            options={IssueOptions}
                        />
                    )}
                />
                <button className="bg-nt-red text-white px-2 py-3 rounded w-full">Yes</button>
                <button type="button" className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => setIsCancel(false)}>No</button>
            </div>
        </form>
    </div>
}
export { CancelRangeEvent }