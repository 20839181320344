import { useFieldArray, useFormContext } from "react-hook-form";
import { SingleDaySlices } from "./singleDaySlices";

const DaysAvailability = ({widthClass= " w-full xl:w-1/2 "}) => {

    const {
        control,
    } = useFormContext();


    const {
        fields: daysList,
    } = useFieldArray({
        control,
        name: "days",
    });

    const options = [...Array(25)].map((e, i) => {
        let time = (i < 10 ? '0' : '') + (i < 24 ? i : '00') + ':00';
        return {
            value: i,
            label: time
        }
    });

    return (
        <div className={widthClass}>
            <h2 className="mb-5 text-nt-blue">Business Hours</h2>
            <div className="hidden xl:flex border-b-2 mb-2 border-nt-grey">
                <p className="w-36">Day</p>
                <p className="w-[140px]">Start Time</p>
                <p className="w-[140px]">End Time</p>
            </div>
            <div className="flex-row items-center">
                {daysList.map((day, index) => {
                    return <SingleDaySlices options={options} name={day.name} id={index} key={index} />
                })}
            </div>
        </div>
    )
}

export { DaysAvailability }