import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { SelectInput } from "../inputs";
import { CheckboxInput } from "../inputs/checkboxInput";
import { FiTrash } from "react-icons/fi";
import { useEffect } from "react";

const SingleDaySlices = ({ id, name, options }) => {
    const {
        register,
        formState:{errors},
        watch,
        control,
        getValues,
    } = useFormContext();

    const dayOff = watch(`days[${id}].off`, false);

    const {
        fields: sliceList,
        append: addSlice,
        remove: removeSlice
    } = useFieldArray({
        control,
        name: `days[${id}].slices`,
    });
    
    const lastSliceStart = watch(`days[${id}].slices[${sliceList.length - 1}].start`)
    const lastSliceEnd = watch(`days[${id}].slices[${sliceList.length - 1}].end`)

    return (
        <div className="flex-wrap flex mb-5 justify-center xl:justify-start">

            <Controller
                control={control}
                name={`days[${id}].slices`}
                key={id}
                className={` flex`}
                render={({ field }) => (
                    <div className="flex-col lg:flex-row flex justify-center xl:items-start">
                        <p className="w-full lg:w-36">{name}</p>
                        <div className="flex flex-col order-2 lg:order-none">
                            {sliceList.map((slice, index) => {
                                const sliceStart = watch(`days[${id}].slices[${index}].start`);
                                const sliceEnd = getValues(`days[${id}].slices[${index - 1}].end`);
                                return (
                                    <div className="flex w-full" key={id + "-" + index}>
                                        <Controller
                                            control={control}
                                            name={`days[${id}].slices[${index}].start`}
                                            render={({ field }) => (
                                                <SelectInput
                                                    name={`days[${id}].slices[${index}].start`}
                                                    errors={errors}
                                                    register={register}
                                                    control={control}
                                                    options={index > 0 ? options.filter((v) => v.value > sliceEnd && v.value < 24) : options.filter(v => v.value < 24)}
                                                    onChangeSelect={event => {
                                                        field.onChange(event.value)
                                                    }}
                                                    disabled={dayOff}
                                                    hideLabel={true}
                                                    styles={{ width: "130px" }}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`days[${id}].slices[${index}].end`}
                                            render={({ field }) => (
                                                <SelectInput
                                                    name={`days[${id}].slices[${index}].end`}
                                                    errors={errors}
                                                    register={register}
                                                    control={control}
                                                    options={sliceStart ? options.filter((v) => v.value > sliceStart) : sliceEnd ? options.filter((v) => v.value > sliceEnd) : options}
                                                    onChangeSelect={event => {
                                                        field.onChange(event.value)
                                                    }}
                                                    disabled={dayOff}
                                                    hideLabel={true}
                                                    styles={{ width: "130px" }}
                                                />
                                            )}
                                        />
                                        {index > 0 && <button type="button" onClick={() => removeSlice(index)}><FiTrash /></button>}
                                    </div>
                                )
                            })}
                            {(lastSliceEnd < 23 && !dayOff) &&
                                <button disabled={(!lastSliceStart && lastSliceStart !== 0) || !lastSliceEnd}
                                    className="bg-nt-blue text-white rounded py-2 mt-1"
                                    onClick={() => addSlice({})}>Add slice</button>}
                        </div>
                        <div className="self-end lg:self-auto -mt-5 lg:mt-0">
                            <CheckboxInput
                                name={`days[${id}].off`}
                                label="Day off"
                                register={register}
                                control={control}
                                className="order-1 lg:order-none justify-self-end "
                            />
                        </div>
                        
                    </div>
                )}
            />
        </div>
    )
}
export { SingleDaySlices };