import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { errorToast, successToast } from '../../utils';
import { registerUser } from '../../store/auth/authActions';
import Styles from './register.module.scss';
import { ConfirmEmail } from './confirmEmail';
import {RegisterForm} from './registerForm'

export const Register = () => {
	const { user } = useSelector((state) => state.auth);

	return (
		<>
			<Toaster />
			{
				user === null && <RegisterForm />
			}
			{
				(user !== null && !user.confirmed) && <ConfirmEmail />
			}
		</>
	);
};
