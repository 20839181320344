import { parseObjByString } from '../../utils';
import Styles from './inputs.module.scss';

const TextareaInput = ({ name, label = "", register, errors, required, type, validationSchema }) => {
    return (
        <div className={Styles.textInput}>
            {label.trim() !== "" && <label htmlFor={name} className='block text-left text-primary-grey text-[13px] font-semibold pb-1'>
                {label}
                {required && "*"}
            </label>}
            <div className="relative">
                <textarea
                    className='inputField w-full'
                    id={name}
                    name={name}
                    type={type}
                    {...register(name, validationSchema)}
                ></textarea>
                {errors && (
                    errors[name]?.type === "required" ||
                    (parseObjByString(errors, name).length && parseObjByString(errors, name)[0]?.type === "required")
                ) && (
                        <span className="error">{errors[name] ? errors[name]?.message : parseObjByString(errors, name)[0]?.message}</span>
                    )}
                {errors && errors[name]?.type === "minLength" && (
                    <span className="error">{errors[name]?.message}</span>
                )}
            </div>
        </div>
        //  <label className="block text-primary-grey text-[13px] font-medium pb-1">Password</label>
        //  <div className="relative">
        //      <input
        //          className="inputField mb-8 w-full"
        //          name="password"
        //          placeholder="Enter your password"
        //          id="password"
        //          onChange={() => setValidationError('')}
        //          ref={(e) => { password = e; } }
        //          type="password"
        //          required />
        //  </div>
    )
};
export { TextareaInput };