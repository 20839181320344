import { createSlice } from '@reduxjs/toolkit';
import { resetAppointmentNotification } from './notificationActions';

const initialState = {
	loading: false,
	appointments: 4,
	messages: 3,
	wallet: 2,
	support: 3,
	error: null,
	success: false,
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {},
	extraReducers: {
		[resetAppointmentNotification.fulfilled]: (state, action) => {
			state.appointments = 0
		},
	},
});

export default notificationSlice.reducer;
