import Styles from './widgets.module.scss';

const NumberIcon = ({ title, Icon, number, className = "", titleClassName = "", numberClassName = "" }) => {
    return (
        <div className={Styles.numberWidget + " " + className}>
            <div className={Styles.icon}>
                <Icon size={"24px"} />
            </div>
            <div className={Styles.content }>
                <h3 className={"font-light" + " " + titleClassName}>{title}</h3>
                <h2 className={"text-right" + " " + numberClassName}>{number}</h2>
            </div>
        </div>
    )
}

export { NumberIcon }