import { FormProvider, useForm } from "react-hook-form";
import { DaysAvailability } from "../../components/settings/daysAvaibility";
import { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { getSchedule, setWeekSchedule } from "../../store/appointment/appointmentActions";
import { errorToast, getDeviceTimeZone, successToast } from "../../utils";
import { RangeEventSelected } from "../calendar/rangeEventSelected";
import { RangeEventBlocked } from "./availability/rangeEventBlocked";

const AvailabilitySettings = () => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", ]
    const [isOpen, setIsOpen] = useState(false)
    const [events, setEvents] = useState(false)
    const dispatch = useDispatch();
    const methods = useForm();
    const [isReschedule, setIsReschedule] = useState(false);
    const [isCancel, setIsCancel] = useState(false);

    useEffect(() => {
        dispatch(getSchedule())
        .unwrap()
        .then(daysResponse => {
            methods.reset({
                days: daysResponse.days.map(singleDay => {
                    return {
                        ...singleDay,
                        name: days[singleDay.day]
                    }
                })
            })
        })
    }, [])

    const onSubmit = async (data) => {
        console.log(data.reschedule)
        if(data.isCancel == "1" && isCancel){
            data = {
                days:data.days, 
                appointments: {
                    reason: data.reason.label,
                    action: "cancel",
                    events: events.map((v) => v._id)
                }
            }
        }else if(data.isReschedule == "1" && isReschedule){
            data = {
                days:data.days, 
                appointments: {
                    action: "reschedule",
                    timezone: getDeviceTimeZone(),
                    events: data.reschedule.map((v) => {
                        console.log(v.time.value.utc())
                        return ({_id: v._id, time: v.time, message: v.message, reason: v.reason})}),
                }
            }
        }

        console.log("*", data)
        dispatch(setWeekSchedule(data))
        .unwrap()
        .then(v => {
            if(v?.existAppointments){
                setEvents(v.list)
                errorToast("Please check following appointments")
                setIsOpen(true)
            }else{
                setIsOpen(false)
                successToast("Modification applied successfully")
                dispatch(getSchedule())
                .unwrap()
                .then(daysResponse => {
                    methods.reset({
                        days: daysResponse.days.map(singleDay => {
                            return {
                                ...singleDay,
                                name: days[singleDay.day]
                            }
                        })
                    })
                })
            }
        })
    };

    return (
        <div className="w-full 2xl:w-5/6 mx-auto">
            <form onSubmit={methods.handleSubmit(onSubmit)} disabled={isOpen} className="bg-white relative p-8 rounded-2xl flex flex-col justify-center" >
                <FormProvider {...methods}>
                    
                    <div className="flex flex-col md:flex-row gap-5 relative justify-center" >
                        <DaysAvailability {...methods} widthClass={`w-full 2xl:w-1/2 relative ${isOpen ? 'after:absolute after:top-0 after:left-0 after:h-full after:w-full z-[999999999] after:bg-gray-200 after:opacity-80': ''}`}  />
                        {
                            isOpen ? <div className="w-full md:w-1/2">
                            <RangeEventBlocked 
                                setIsOpen={(v) => setIsOpen(v)}
                                events={events} 
                                isReschedule={isReschedule}
                                setIsReschedule={setIsReschedule}
                                isCancel={isCancel}
                                setIsCancel={setIsCancel}
                                />
                            </div>
                        : null}
                    </div>
                </FormProvider>
              
                
                <input className="self-end bg-nt-blue text-white rounded p-2 mt-5 cursor-pointer" type="submit" value={"Submit"} />
            </form>
        </div>

    )
}

export { AvailabilitySettings }