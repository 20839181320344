import Styles from './inputs.module.scss';
import { Controller } from 'react-hook-form'
import Select from 'react-select'

const SelectInput = ({ name, isSearchable, isMulti=false, placeholder="Select...", label, hideLabel=false,isClearable=false, options,onChangeSelect = null, disabled, register, errors, styles,required, type, validationSchema, control }) => {
    
    return (
        <div className={Styles.selectInput + " w-full"}>
            {!hideLabel && <label htmlFor={name} className='block text-left text-primary-grey text-[13px] font-semibold pb-1'>
                {label}
                {validationSchema && validationSchema?.required ? " *": ""}
            </label>}
            {/* mb-8 */}
            <div className="relative text-left">
                <Controller
                    control={control}
                    id={name}
                    name={name}
                    defaultValue={""}
                    rules={validationSchema}
                    render={({ field: { value, onChange } }) => (
                        <Select
                            isSearchable={isSearchable}
                            value={ options.filter(option => option.value === value)}
                            onChange={(v) => {
                                onChange(v ? v.value : null)
                                onChangeSelect !== null && onChangeSelect(v);
                            }}
                            isClearable={isClearable}
                            placeholder={placeholder}
                            isMulti={isMulti}
                            className={`inputField w-full flex-1 ${Styles.reactSelectContainer}`}
                            options={options}
                            isDisabled={disabled}
                            styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  ...styles
                                }),
                            }}
                        />
                    )}
                />  

                {errors && errors[name]?.type === "required" && (
                    <span className="left error">{errors[name]?.message}</span>
                )}
                {errors && errors[name]?.type === "minLength" && (
                    <span className="error">{errors[name]?.message}</span>
                )}
            </div>
        </div>)
};
export { SelectInput };