import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logoutUser, verifyUserDetails } from '../../store/auth/authActions';

const Navbar = () => {
	const dispatch = useDispatch();
	const { user, accessToken, refreshToken } = useSelector((state) => state.auth);
	console.log(refreshToken);
	// useEffect(() => {
	// 	if (accessToken) {
	// 		dispatch(verifyUserDetails(refreshToken));
	// 	}

	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	return (
		<header>
			<div>
				{/* <span>
					{user ? `Logged in as ${user.email}` : "You're not logged in"}
				</span> */}
				<div>
					{user ? (
						<NavLink onClick={logoutHandler}>
							{/* Logout */}
						</NavLink>
					) : (
						<NavLink to='/login'>
							{/* Login */}
						</NavLink>
					)}
				</div>
			</div>
			{/* <nav>
				<ul>
					<li><NavLink to='/'>Home</NavLink></li>
				</ul>
			</nav> */}
		</header>
	);
};

export { Navbar };
