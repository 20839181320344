import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const sendChatMessage = createAsyncThunk('chat/send/message', async (message, { rejectWithValue, getState }) => {
	try {

		const response = await axiosConfig.post('/chat/send', {
			message: message,
			from: getState().profile.currentProfile._id,
			to: getState().chat.currentChat.profile
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const sendFirstMessage = createAsyncThunk('chat/send/first/message', async ({message, to}, { rejectWithValue, getState }) => {
	try {

		const response = await axiosConfig.post('/chat/send', {
			message: message,
			from: getState().profile.currentProfile._id,
			to: to
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const deleteChatMessage = createAsyncThunk('chat/delete/message', async (id, { rejectWithValue, getState }) => {
	try {
		let profile = getState().profile.currentProfile._id
		const response = await axiosConfig.post('/chat/delete', {
			id: id,
			profile: profile
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getChatFiles = createAsyncThunk('chat/files', async (id, { rejectWithValue, getState }) => {
	try {
		let profile = getState().profile.currentProfile._id
		const response = await axiosConfig.post('/chat/files', {
			id: id,
			profile: profile
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const sendChatFile = createAsyncThunk('chat/send/file', async (file, { rejectWithValue, getState }) => {
	try {
		let formData = new FormData();
		formData.append("from",  getState().profile.currentProfile._id);
		formData.append("to",  getState().chat.currentChat.profile);
		formData.append("file", file);

		const response = await axiosConfig.post('/chat/file', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		getConversationList();
		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getChatMessages = createAsyncThunk('chat/messages', async (id, { rejectWithValue, getState }) => {
	try {
		console.log(getState().profile.currentProfile._id)
		const response = await axiosConfig.post('/chat/messages', {
			id
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getConversationList = createAsyncThunk('chat/list', async (_, { rejectWithValue, getState }) => {
	try {
		console.log(getState().profile.currentProfile._id)
		const response = await axiosConfig.post('/chat/list', {
			profile: getState().profile.currentProfile._id,

		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getAdminSupport = createAsyncThunk('chat/admin/list', async (_, { rejectWithValue, getState }) => {
	try {
		console.log(getState().profile.currentProfile._id)
		const response = await axiosConfig.post('/chat/admin/list', {
			profile: "420420420420420420420420",
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getFullChat = createAsyncThunk('chat/single', async (id, { rejectWithValue, getState }) => {
	try {
		console.log(getState().profile.currentProfile._id)
		const response = await axiosConfig.post('/chat/single', {
			profile: getState().profile.currentProfile._id,
			id: id
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const goToSupport = createAsyncThunk('chat/support', async (_, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/chat/support', {
			profile: getState().profile.currentProfile._id,
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});
