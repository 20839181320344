import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProfileOverview } from "../../components/profile/overview"
import { ProfileResume } from "../../components/profile/profileResume"
import { getFullTutorProfile } from "../../store/profile/profileActions"


const SettingPreview = () => {
    const { currentProfile } = useSelector((state) => state.profile)
    const [profile, setProfile] = useState({}) 
    const [isLoading, setIsLoading] = useState(true) 
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(getFullTutorProfile({profileId: currentProfile._id}))
        .unwrap()
        .then(v => {
            setProfile(v);
            setIsLoading(false)
        }) 
    }, [])

    return (
        <>
            {!isLoading ? <ProfileOverview showReview={false} profile={{...profile}} link={false} showStats={false} />: null}
            {!isLoading ? <ProfileResume profile={profile} />: null}
            
        </>
    )
}

export { SettingPreview }