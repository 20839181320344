import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAppointmentNotification } from "../../store/notification/notificationActions";
import { getAllWithdraw, getWithdrawList } from "../../store/wallet/walletActions";
import { WithdrawRequest } from './widthdrawRequest';
import { WithdrawList } from './withdrawList';

const Withdraw = () => {
    const { currentProfile } = useSelector(state => state.profile)
    const [isRequest, setIsRequest] = useState(false);
    const [nodes, setNodes] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        initView()
    }, [])

    const initView = () => {
        if (currentProfile.role == 1) {
            dispatch(getAllWithdraw())
                .unwrap()
                .then((v) => setNodes(v))
        } else {

            dispatch(getWithdrawList())
                .unwrap()
                .then((v) => setNodes(v))
        }
        dispatch(resetAppointmentNotification())
    }
    
    return (
        <>
            {!isRequest ? <WithdrawList 
                setIsRequest={setIsRequest} 
                initView={initView} 
                nodes={nodes}
                setNodes={setNodes}
                /> : 
            <WithdrawRequest initView={initView} setIsRequest={setIsRequest} />}
        </>

    )
}

export { Withdraw }