import { createSlice } from '@reduxjs/toolkit';
import {
	getModerationList
} from './userActions';


const initialState = {
	loading: false,
	users: [],
	modetationList: [],
	error: false,
};

const userSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		// setCredentials: (state, action) => {
		// 	state.user = action.payload.user;
		// }
	},
	extraReducers: {
		[getModerationList.pending]: (state) => {
			state.loading = true;
		},
		[getModerationList.fulfilled]: (state, action) => {
			state.loading = false;
			state.modetationList = action.payload.data;
		},
		[getModerationList.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			console.log(action)
			// state.error = action.payload.error;
		},
	},
});

export default userSlice.reducer;
