import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { loginUser } from '../../store/auth/authActions';
import { errorToast } from '../../utils';

import { ConfirmEmail } from '../register';
import { setProfile } from '../../store/profile/profileSlice';
import { useForm } from 'react-hook-form';
import { getUserProfiles } from '../../store/profile/profileActions';
export const Login = () => {
	const { user, loading } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const {
        register,
        handleSubmit,
        reset,
        setError,
        control,
        formState: { errors },
    } = useForm();  

	const signinUser = async (data) => {
		dispatch(loginUser({
			email: data.email,
			password: data.password
		}))
			.unwrap()
			.then((data) => {
				if(data.user.profile)
					dispatch(setProfile(data.user.profile))
				dispatch(getUserProfiles())
			})
			.catch((errorData) => {
				console.log(errorData)
				errorToast(errorData.error);
			});
	};

	const LoginForm = () => {
		return (<form onSubmit={handleSubmit(signinUser)}>
			<h1 className='text-nt-blue text-center mb-5'>Sign In</h1>
			<div>
				<label className="block text-primary-grey text-[13px] font-medium pb-1">Email</label>
				<div className="relative">
					<input
						className="inputField w-full mb-8"
						name="email"
						placeholder="Enter your email"
						id="email"
						defaultValue={""}
						{...register("email")}
						type="email"
						required />
				</div>
			</div>
			<div>
				<label className="block text-primary-grey text-[13px] font-medium pb-1">Password</label>
				<div className="relative">
					<input
						className="inputField w-full mb-8"
						name="password"
						placeholder="Enter your password"
						id="password"
						defaultValue={""}
						{...register("password")}
						type="password"
						required />
				</div>
			</div>
			<button type="submit" className="primaryButton mb-5">
				{loading ? 'Loading...' : 'Login'}
			</button>
			<div className='text-center'>
				<p className='mb-2'>
					<Link to='/forgot' className="link">Forgot your password ?</Link>
				</p>
				<p>Don't have an account ? <Link to='/register' className="link">Register</Link>
				</p>
			</div>
		</form>)
	}
	return (
		<>
			<Toaster />
			{
				user === null && <LoginForm />
			}
			{
				(user !== null && !user.confirmed) && <ConfirmEmail />
			}
		</>
	);
};
