import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getModerationList = createAsyncThunk('user/moderate', async ({}, { rejectWithValue }) => {
	console.log("aaaa")
	try {
		const response = await axiosConfig.post('/users/moderate/list', {}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});
