import { useForm, useFormContext } from "react-hook-form";
import { SelectInput, TextInput } from "../inputs";
import Langs from '../../utils/data/langs.json'
import countries from "../../utils/data/countries.json"
import { useDispatch } from "react-redux";
import { searchTutor } from "../../store/profile/profileActions";
import { useState } from "react";

const SearchTutorBar = ({handleSearch}) => {
    const {
        register,
        control,
        errors,
    } = useFormContext();

    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const options = countries.map((country) => { return { value: country.iso, label: country.name } })

    const dispatch = useDispatch();

   

    return (
        <div className="flex flex-col xl:flex-row w-full">
            <SelectInput
                name={`teach`}
                onChangeSelect={() => handleSearch(0)}
                label=""
                errors={errors}
                register={register}
                control={control}
                options={languages}
                placeholder="I want to learn"
                styles={{ width: "100%", }}
                isSearchable={true}
                isClearable={true}

            />
            <SelectInput
                name={`speak`}
                onChangeSelect={() => handleSearch(0)}
                label=""
                errors={errors}
                register={register}
                control={control}
                options={languages}
                placeholder="I speak"
                styles={{ width: "100%" }}
                // isSearchable={true}
                // isMulti={true}
                isClearable={true}
            />
            <SelectInput
                name={`country`}
                label=""
                onChangeSelect={() => handleSearch(0)}
                errors={errors}
                register={register}
                control={control}
                options={options}
                placeholder="Country"
                styles={{ width: "100%" }}
                isSearchable={true}
                // isMulti={true}
                isClearable={true}
            />
            <div className="w-full h-fit border-b-[1px] border-nt-grey pb-1">
                <div className="relative text-left">
                    <input
                        className={'mt-2 h-[38px] text-[18px] placeholder:text-[#808080] border-b-[1px]  ps-2 w-full text-nt-blue rounded'}
                        name={"price"}
                        placeholder={"Price"}
                        type="number"
                        {...register("price", {onChange: () => handleSearch(0)})}
                    />
                </div>
            </div>
        </div>
    )
}

export { SearchTutorBar }