import moment from "moment"
import { useSelector } from "react-redux"

const AppointmentHistory = ({ history, otherName }) => {
    console.log(history)
    const { user } = useSelector((state) => state.auth)
    const { currentProfile } = useSelector((state) => state.profile)
    const RescheduleItem = ({ reason, by, message, date, at }) => {
        return <div className="border-[1px] overflow-hidden border-nt-grey-light rounded-lg text-nt-blue text-sm mt-2">
            <div className="bg-nt-grey-light px-2 py-1 flex sm:flex-row flex-col justify-between">
                <span><b className="capitalize">{by}</b> has rescheduled this appointment to <b>{moment(date).format("YYYY-MM-DD HH:mm")}</b></span>
                <span className="text-nt-grey">{moment(at).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <ul className="bg-white p-2 text-nt-blue">
                <li><b>Reason:</b> {reason}</li>
                <li><b>Message:</b> '{message}'</li>
            </ul>
        </div>
    }

    const ApproveItem = ({ by, at }) => {
        return <div className="border-[1px] overflow-hidden border-nt-grey-light rounded-lg text-nt-blue text-sm mt-2">
            <div className="bg-nt-grey-light px-2 py-1 flex sm:flex-row flex-col justify-between">
                {by ? <span><b className="capitalize">{by}</b> has approved the payment</span> : <span><b>The system</b> has automatically approved the payment</span>}
                <span className="text-nt-grey">{moment(at).format("YYYY-MM-DD HH:mm")}</span>
            </div>

        </div>
    }

    const CancelItem = ({ reason, by, at }) => {
        return <div className="border-[1px] overflow-hidden border-nt-grey-light rounded-lg text-nt-blue text-sm mt-2">
            <div className="bg-nt-grey-light border-nt-grey-light px-2 py-1 flex sm:flex-row flex-col justify-between">
                <span><b className="capitalize">{by}</b> has cancelled this course</span>
                <span className="text-nt-grey">{moment(at).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <ul className="bg-white p-2 text-nt-blue">
                <li><b>Reason:</b> {reason}</li>
            </ul>
        </div>
    }

    const ReportItem = ({ reason, by, at, message }) => {
        return <div className="border-[1px] overflow-hidden border-nt-grey-light rounded-lg text-nt-blue text-sm mt-2">
            <div className="bg-nt-grey-light border-nt-grey-light px-2 py-1 flex sm:flex-row flex-col justify-between">
                <span><b className="capitalize">{by}</b> reported an issue</span>
                <span className="text-nt-grey">{moment(at).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <ul className="bg-white p-2 text-nt-blue">
                <li><b>Reason:</b> {reason}</li>
                {(message && message.trim() != "") && <li><b>More Informations:</b> {message}</li>}
            </ul>
        </div>
    }
    return <div className="mt-5">
        {history.length > 0 ? <h3 className="text-nt-blue mb-2">History</h3> : ''}
        {history.map((v, i) => {
            if (v.type === "report") {
                return <ReportItem key={i} reason={v.reason} at={v.createdAt} by={currentProfile._id === v.by ? user.firstName : otherName} message={v.message} />
            } else if (v.type === "cancel") {
                return <CancelItem key={i} reason={v.reason} at={v.createdAt} by={currentProfile._id === v.by ? user.firstName : otherName} />
            } else if (v.type === "approve") {
                return <ApproveItem key={i} reason={v.reason} at={v.createdAt} by={currentProfile._id === v.by ? user.firstName : otherName} />
            } else if (v.type === "reschedule") {
                return <RescheduleItem
                    key={i}
                    reason={v.reason}
                    at={v.createdAt}
                    date={v.newDate}
                    message={v.message}
                    by={currentProfile._id === v.by ? user.firstName : otherName} />
            }
        })}
    </div>
}

export { AppointmentHistory }