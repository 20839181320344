import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { TextInput } from "../inputs"
import Langs from '../../utils/data/langs.json'
const Tarif = () => {

    const {
        register,
        formState: { errors },
        watch,
        control,
    } = useFormContext();

    const {
        fields: langList,
    } = useFieldArray({
        control,
        name: "prices",
    });


    return (
        <div className="xl:w-1/2 mx-auto">
            <h2 className="mb-5 text-nt-blue">Tarif</h2>
            <div className="xl:grid gap-10 xl:grid-cols-2">
                <Controller
                    control={control}
                    name="prices"
                    render={({ field }) => {
                        {
                            return langList.map((lang, index) => {
                                const five = watch(`prices[${index}].priceFive`);
                                const price = watch(`prices[${index}].price`);

                                return (<div key={index}>
                                    <h4 className="text-nt-blue mb-5">{Langs.filter((v) => v.value == lang.abbr)[0].name}</h4>
                                    <div className="">

                                        <TextInput
                                            name={`prices[${index}].price`}
                                            label={"Single Course"}
                                            errors={errors}
                                            type="number"
                                            register={register}
                                            control={control}
                                            inputStyle={{ width: "50px" }}
                                            validationSchema={{
                                                required: "This field is required",
                                                validate: (value) => {
                                                    return value < 10 ? "Minimum value is 10" : true;
                                                }
                                            }}

                                        />
                                        <TextInput
                                            name={`prices[${index}].priceFive`}
                                            label={"More than 5 Courses"}
                                            errors={errors}
                                            type="number"
                                            register={register}
                                            control={control}
                                            inputStyle={{ width: "50px" }}
                                            validationSchema={{
                                                required: "This field is required",
                                                validate: (value) => {
                                                    return value < 10 ?
                                                        "Minimum value is 10" :
                                                        value > price ?
                                                            "Maximum value is " + price :
                                                            true;
                                                }
                                            }}

                                        />
                                        <TextInput
                                            name={`prices[${index}].priceTen`}
                                            label={"More than 10 Courses"}
                                            errors={errors}
                                            type="number"
                                            register={register}
                                            control={control}
                                            inputStyle={{ width: "50px" }}
                                            validationSchema={{
                                                required: "This field is required",
                                                validate: (value) => {
                                                    return value < 10 ?
                                                        "Minimum value is 10" :
                                                        value > five ?
                                                            "Maximum value is " + five :
                                                            true;
                                                }
                                            }}

                                        />
                                    </div>

                                </div>)
                            })
                        }
                    }}

                />
            </div>
            <div className="bg-nt-grey-light p-5 rounded-xl w-full">
                <table className="w-full">
                    <thead>
                        <tr>
                            <th className="text-nt-blue text-left align-top">Total number of completed hours</th>
                            <th className="text-nt-blue text-left align-top">Commission rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0-20 hours</td>
                            <td><span className="line-through decoration-2 decoration-nt-red mr-4">30%</span>15%</td>
                        </tr>
                        <tr>
                            <td>21 - 50 hours</td>
                            <td><span className="line-through decoration-2 decoration-nt-red mr-4">28%</span>14%</td>
                        </tr>
                        <tr>
                            <td>51 - 200 hours</td>
                            <td><span className="line-through decoration-2 decoration-nt-red mr-4">25%</span>12.5%</td>
                        </tr>
                        <tr>
                            <td>201 - 400 hours</td>
                            <td><span className="line-through decoration-2 decoration-nt-red mr-4">22%</span>11%</td>
                        </tr>
                        <tr>
                            <td>400+ hours</td>
                            <td><span className="line-through decoration-2 decoration-nt-red mr-4">18%</span>9%</td>
                        </tr>
                    </tbody>
                </table>
                <div className="flex flex-col sm:flex-row mt-5 justify-between border-t-2 border-nt-blue">
                <p className="">* Each first lesson is <b>Free</b></p>
                <p className="">* Discount active until <b>31/12/2024</b></p>
                </div>
            </div>
        </div>
    )
}

export { Tarif }