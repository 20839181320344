import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import Styles from './register.module.scss'

const StepNavigation = ({moveBack, moveForward, isLoading = false}) => {

    return (
        <div className={"flex gap-4 mb-5 justify-center"}>
            {moveBack !== false && <button disabled={isLoading} className='bg-nt-blue rounded-full p-2'
                onClick={() => moveBack()}
            >
                <FiArrowLeft color="#fff" fontSize={20} />
            </button>}

            <button type='button'
                onClick={(e) => {moveForward()}} disabled={isLoading} className='bg-nt-blue rounded-full p-2'
            >
                <FiArrowRight color="#fff" fontSize={20} />
            </button>
        </div>
    )
}

export {StepNavigation}