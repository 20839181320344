import Styles from '../../components/auth/register/register.module.scss';
import Langs from '../../utils/data/langs.json'
import { Controller } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { SelectInput, TextInput } from '../inputs';

const LanguagesPrice = ({ id, index, remove, name, control, register, errors, isFirstSysLang = false, required, currentValue, watch, length }) => {
    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const levels = [
        { value: 'A1', label: 'A1' },
        { value: 'A2', label: 'A2' },
        { value: 'B1', label: 'B1' },
        { value: 'B2', label: 'B2' },
        { value: 'C1', label: 'C1' },
        { value: 'C2', label: 'C2' },
        { value: 'native', label: 'Native' },
    ]
    const selectedLangs = currentValue.map((v) => v.lang);
    const langOptions = (isFirstSysLang && index === 0) ? languages.filter((l) => ['fr', 'en'].includes(l.value)) :
        currentValue.length > 0 ? languages.filter((l) => !selectedLangs.includes(l.value) || selectedLangs[index] == l.value) : languages;

    return (
        <Controller
            control={control}
            name={`${name}[${index}]`}
            key={id}
            className={Styles.spokenLanguages + " flex"}
            render={({ field }) => {
                var five = watch(`${name}[${index}].priceFive`);
                var price = watch(`${name}[${index}].price`);

                return (
                    <div className='flex flex-wrap items-center gap-x-8' key={field.id}>
                        <div className='w-full md:grid grid-flow-col gap-2'>
                            <div className='w-full flex md:block md:col-span-8'>
                                <SelectInput
                                    hideLabel={true}
                                    name={`${name}[${index}].lang`}
                                    label=""
                                    errors={errors}
                                    register={register(`${name}[${index}].lang`)}
                                    control={control}
                                    options={langOptions}
                                    isSearchable={true}
                                    required
                                    onChangeSelect={event => {
                                        console.log(event)
                                        field.onChange({ ...field.value, lang: event.value })
                                    }}
                                />
                                {index !== 0 && <div className='mt-4 md:hidden'>
                                    <button type="button" onClick={() => remove(index)}>
                                        <FiTrash2 size={20} color='#df2b47' />
                                    </button>
                                </div>}
                            </div>
                            <div className='col-span-2'>
                                <SelectInput
                                    hideLabel={true}
                                    name={`${name}[${index}].level`}
                                    label=""
                                    errors={errors}
                                    register={register(`${name}[${index}].level`)}
                                    control={control}
                                    options={levels}
                                    required={required}
                                    onChangeSelect={event => {
                                        field.onChange({ ...field.value, level: event.value })
                                    }}
                                />
                            </div>
                                {index !== 0 && <div className='mt-4 hidden md:block'>
                                    <button type="button" onClick={() => remove(index)}>
                                        <FiTrash2 size={20} color='#df2b47' />
                                    </button>
                                </div>}
                        </div>
                        <div className='w-full flex md:flex-row flex-col mt-4'>
                            <TextInput
                                name={`${name}[${index}].price`}
                                label={"Single Course"}
                                errors={errors}
                                type="number"
                                register={register}
                                control={control}
                                inputStyle={{ width: "50px" }}
                                validationSchema={{
                                    required: "This field is required",
                                    validate: (value) => {
                                        return value < 10 ? "Minimum value is 10" : true;
                                    }
                                }}

                            />
                            <TextInput
                                name={`${name}[${index}].priceFive`}
                                label={"More than 5 Courses"}
                                errors={errors}
                                type="number"
                                register={register}
                                control={control}
                                inputStyle={{ width: "50px" }}
                                validationSchema={{
                                    required: "This field is required",
                                    validate: (value) => {
                                        return value < 10 ?
                                            "Minimum value is 10" :
                                            value > price ?
                                                "Maximum value is " + price :
                                                true;
                                    }
                                }}

                            />
                            <TextInput
                                name={`${name}[${index}].priceTen`}
                                label={"More than 10 Courses"}
                                errors={errors}
                                type="number"
                                register={register}
                                control={control}
                                inputStyle={{ width: "50px" }}
                                validationSchema={{
                                    required: "This field is required",
                                    validate: (value) => {
                                        return value < 10 ?
                                            "Minimum value is 10" :
                                            value > five ?
                                                "Maximum value is " + five :
                                                true;
                                    }
                                }}

                            />
                        </div>
                        {index !== length - 1 ? <span className='block md:hidden mx-auto mb-2 w-2/4 h-1 bg-nt-grey-light'></span>: ''}
                    </div>
                )
            }}
        />
    );
}
export { LanguagesPrice };