import { SelectInput } from '../../inputs';
import Styles from './register.module.scss';
import Langs from '../../../utils/data/langs.json'
import { Controller } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';

const Languages = ({ id, index, remove, name, control, register, errors, isFirstSysLang = false, required, length, currentValue }) => {
    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const levels = [
        { value: 'A1', label: 'A1' },
        { value: 'A2', label: 'A2' },
        { value: 'B1', label: 'B1' },
        { value: 'B2', label: 'B2' },
        { value: 'C1', label: 'C1' },
        { value: 'C2', label: 'C2' },
        { value: 'native', label: 'Native' },
    ]
    const selectedLangs = currentValue.map((v) => v.lang);
    const langOptions = (isFirstSysLang && index === 0) ? languages.filter((l) => ['fr', 'en'].includes(l.value)) : 
        currentValue.length > 0 ? languages.filter((l) => !selectedLangs.includes(l.value) || selectedLangs[index] == l.value) : languages;
    
    return (
        <Controller
            control={control}
            name={`${name}[${index}]`}
            key={id}
            className={Styles.spokenLanguages + " flex"}
            render={({ field }) => (
                <div className='sm:grid grid-flow-col gap-4 mb-4' key={field.id}>
                    <div className='sm:col-span-6 flex'>
                        <SelectInput
                            hideLabel={true}
                            name={`${name}[${index}].lang`}
                            label=""
                            errors={errors}
                            register={register(`${name}[${index}].lang`)}
                            control={control}
                            options={langOptions}
                            isSearchable={true}
                            required
                            // validationSchema={{
                            //     required: "This field is required"
                            // }}
                            onChangeSelect={event => {
                                console.log(event)
                                field.onChange({ ...field.value, lang: event.value })
                            }}
                        />
                        {index !== 0 && <div className='md:hidden mt-4'>
                        <button type="button" onClick={() => remove(index)}>
                            <FiTrash2  size={20} color='#df2b47'/>
                        </button>
                    </div>}
                    </div>
                    <div className='col-span-2'>
                        <SelectInput
                            hideLabel={true}
                            name={`${name}[${index}].level`}
                            label=""
                            errors={errors}
                            register={register(`${name}[${index}].level`)}
                            control={control}
                            options={levels}
                            required={required}
                            onChangeSelect={event => {
                                field.onChange({ ...field.value, level: event.value })
                            }}
                        />
                    </div>
                    {index !== 0 && <div className='mt-4 hidden md:block col-span-1'>
                        <button type="button" onClick={() => remove(index)}>
                            <FiTrash2  size={20} color='#df2b47'/>
                        </button>
                    </div>}
                    {index < length - 1 ? <span className='block mt-4 sm:hidden mx-auto mb-2 w-2/4 h-1 bg-nt-grey-light'></span>: ''}

                </div>
            )}
        />
    );
}
export { Languages };