import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { sendFirstMessage } from "../../store/chat/chatActions";

const AskMe = ({id}) => {

    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [isSent, setIsSent] = useState(false)
    const sendFirstMsg = () => {
        console.log(message)
        dispatch(sendFirstMessage({
            to: id,
            message: message
        }))
        .unwrap()
        .then((v) =>setIsSent(true))
    }

    return (
        <div className="p-5 rounded-xl mt-5 bg-white">
            <div className="flex gap-5 flex-row justify-between items-center">
                <h3 className="text-nt-blue mb-5">Feel free to ask</h3>
                {!isSent && <button disabled={message.length === 0} onClick={() => sendFirstMsg()} className="text-white bg-nt-blue rounded px-5 py-3">Send</button>}
            </div>

            {!isSent ? <textarea onChange={(e) => setMessage(e.target.value)} rows={"5"} placeholder="Write your question" className="border-2 border-nt-grey w-full rounded-xl mt-5 p-2" />
            : <h5 className="text-nt-blue">Your message has been sent and a new conversation has been created. </h5>}
        </div>
    )
}

export { AskMe }