import { Navigate, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Logo from '../assets/app/logo.png'
import { useEffect } from 'react';

const AuthLayout = ({ component: Component, ...props }) => {

    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (user != null && user.confirmed) {
            navigate('/', { successLogin: true });
        }
    }, [navigate, user]);
    return (
        <div className="w-full bg-nt-blue justify-center md:justify-end min-h-screen md:bg-auth bg-no-repeat bg-contain md:bg-[length:50%] bg-left-bottom flex items-center">
            <div className='sm:w-[500px] w-[90%] mt-2 mb-[100px] md:mb-0 mx-1 md:mx-0 md:mr-[50px] bg-white h-auto rounded-2xl p-[20px] md:p-[40px]'>
                <img src={Logo} alt='NativTalk' className='text-center mx-auto mb-5' />
                <Component {...props} />
            </div>
        </div>
    );
};

export { AuthLayout };
