import { createSlice } from '@reduxjs/toolkit';
import {
	getTutors
} from './moderateActions';

const initialState = {
	isLoading: false,
	list: false,
	tutor: null,
	error: null
};

const moderateSlice = createSlice({
	name: 'moderate',
	initialState,
	reducers: {},
	extraReducers: {
		[getTutors.pending]: (state) => {
			state.loading = true;
		},
		[getTutors.fulfilled]: (state, action) => {
			state.loading = false;
			console.log("first user", action.payload);
			state.user = action.payload;
			state.error = null;
			state.success = true;
		},
		[getTutors.rejected]: (state, action) => {
			state.loading = false;
			console.log(action)
			// state.error = action.payload.error;
		},
	},
});

export default moderateSlice.reducer;
