import Styles from './chat.module.scss'
import GlobalStyle from '../../utils/sass/global.module.scss'
import { ChatBox, Files, Messages } from '../../components/chat';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getConversationList } from '../../store/chat/chatActions';
import { useParams } from 'react-router';
import { FiChevronLeft } from 'react-icons/fi';
import { SocketContext } from '../../utils/socketConfig';

const Chat = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultChat, setDefalultChat] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams()
    const [mobileListOpen, setMobileListOpen] = useState(true)
    const socket = useContext(SocketContext);

    useEffect(() => {
        setIsLoading(true)
        if (id) {
            setDefalultChat(id)
        }
        dispatch(getConversationList())
            .unwrap()
            .then((v) => {
                setIsLoading(false)
            })
    }, []);

    return (
        <div className={Styles.chatPage}>
            <h1 className={GlobalStyle.pageTitle}>Chat</h1>

            <div className={Styles.container + " relative overflow-hidden"}>
                <div className={`${Styles.messages} shadow-md lg:shadow-none absolute z-10 top-0 lg:relative transition-transform duration-300 ease-linear ${mobileListOpen ? "translate-x-0" : " -translate-x-full"} xl:translate-x-0`}>
                    <button className='lg:hidden absolute bg-white ml-2 h-8 w-8 grid place-items-center rounded-lg shadow-md left-[100%]' onClick={() => setMobileListOpen((x) => !x)}>
                        <FiChevronLeft className={`text-nt-blue right-0 transition-transform delay-150 duration-300 ${!mobileListOpen ? 'rotate-180' : ''}`} />
                    </button>
                    {socket != null ? <Messages defaultChat={defaultChat} />: null}
                </div>
                <div className={Styles.chat}>
                    <ChatBox />
                </div>
                <div className={Styles.files + " hidden lg:block"}>
                    <Files />
                </div>
            </div>
        </div>
    )
}

export default Chat;