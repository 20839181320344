import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';
import { getDeviceTimeZone } from '../../utils';

export const getDayAvailability = createAsyncThunk('appointment/day/availability', async ({
	day, profile, isSchedule= false, newWorkHours= []
}, { rejectWithValue }) => {
	console.log(isSchedule)
	try {
		const response = await axiosConfig.post('/appointment/available', {
			selectedDate: day,
			tutorId: profile,
			isSchedule,
			newWorkHours: {
				days: newWorkHours
			}
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getCalendarInfo = createAsyncThunk('appointment/calendarinfo', async ({
	timezone, profile
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/appointment/calendarinfo', {
			timezone: timezone,
			tutorId: profile,
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getSudentCalendarInfo = createAsyncThunk('appointment/student/calendarinfo', async ({
	profile
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/appointment/student/calendarinfo', {
			timezone: getDeviceTimeZone(),
			profile: profile,
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const reviewPayment = createAsyncThunk('appointment/student/calendarinfo', async ({
	result, appointment
}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/paymentreview', {
			result: result,
			appointment: appointment, 
			admin: getState().profile.currentProfile._id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const createOrder = createAsyncThunk('appointment/create', async ({events, tutor, student, lang}, { rejectWithValue }) => {
	try {
		console.log(lang)
		const response = await axiosConfig.post('/appointment/create', {
			events: events,
			tutor: tutor,
			student: student,
			lang: lang
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getPaymentLink = createAsyncThunk('appointment/payment', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/appointment/create', {
			events: data
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getOrderData = createAsyncThunk('appointment/order/data', async (id, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/appointment/order', {
			id: id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const cancelSingleAppointment = createAsyncThunk('appointment/cancel/appointment', async ({id, reason, message}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/cancel', {
			id: id,
			reason: reason,
			message: message,
			profile: getState().profile.currentProfile._id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const cancelAppointmentRange = createAsyncThunk('appointment/range/cancel', async (ids, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/range/cancel', {
			events: ids,
			profile: getState().profile.currentProfile._id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const activateRange = createAsyncThunk('appointment/range/enable', async ({start, end}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/range/enable', {
			tutor: getState().profile.currentProfile._id,
			start: start,
			end: end
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const disableRange = createAsyncThunk('appointment/range/disable', async ({start, end, events, action, reason}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/range/disable', {
			tutor: getState().profile.currentProfile._id,
			start: start,
			end: end,
			events: events,
			action: action,
			reason: reason
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const rescheduleRange = createAsyncThunk('appointment/range/reschedule', async ({start, end, events, timezone}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/range/reschedule', {
			profile: getState().profile.currentProfile._id,
			start: start,
			end: end,
			events: events,
			timezone: timezone,
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getAppointmentList = createAsyncThunk('appointment/get/appointment', async ({profile = undefined, role = undefined}, { rejectWithValue, getState }) => {
	try {
		const userRole = role ? role : getState().profile.currentProfile.role;
		if(userRole == 1){
			const response = await axiosConfig.post('/appointment/get/all', {
				timezone: getDeviceTimeZone(),
			});
			return response.data
		}else{
			const response = await axiosConfig.post('/appointment/get/appointment', {
				profile: profile != undefined ? profile : getState().profile.currentProfile._id,
				role: userRole,
				timezone: getDeviceTimeZone(),
			});
			return response.data
		}
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getSingleAppointment = createAsyncThunk('appointment/single', async (id, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/single', {
			profile: getState().profile.currentProfile._id,
			timezone: getDeviceTimeZone(),
			id: id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const rescheduleSingleAppointment = createAsyncThunk('appointment/single', async (data, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/reschedule', {
			profile: getState().profile.currentProfile._id,
			timezone: getDeviceTimeZone(),
			data: data
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const approveSingleAppointment = createAsyncThunk('appointment/approve/appointment', async ({id}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/approve', {
			id: id,
			profile: getState().profile.currentProfile._id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const reportSingleAppointment = createAsyncThunk('appointment/report/appointment', async ({id, reason, more}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/report', {
			id: id,
			reason: reason,
			more: more,
			profile: getState().profile.currentProfile._id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});


export const setWeekSchedule = createAsyncThunk('appointment/workschedule', async ({
	days, appointments
}, { rejectWithValue, getState }) => {
	console.log(days)
	try {
		const response = await axiosConfig.post('/appointment/workschedule', {
			days: days,
			profile: getState().profile.currentProfile._id,
			timezone: getDeviceTimeZone(),
			appointments: appointments ? appointments : []
		});
		console.log(response)
		return response.data
	} catch (error) {
		console.log(error)
		if(error.response.status === 300){
			return {
				existAppointments: true,
				list : error.response.data.appointments
			}
		}

		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getSchedule = createAsyncThunk('appointment/get/workschedule', async (_, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/appointment/getschedule', {
			profile: getState().profile.currentProfile._id,
			timezone: getDeviceTimeZone(),
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});