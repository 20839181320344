import Styles from './inputs.module.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Controller } from 'react-hook-form'
import countriesMask from "../../utils/data/phoneMasks.json"
 
const PhoneFieldInput = ({ name, label, register, errors, required, type, validationSchema, control, defaultCountry }) => {

    return (
        <div className={Styles.phoneInput + " mt-4"}>
            <label htmlFor={name} className='block text-left text-primary-grey text-[13px] font-semibold pb-1'>
                {label}
                {required && "*"}
            </label>
            <div className="relative">
                <Controller
                    control={control}
                    id={name}
                    name={name}
                    defaultValue={""}
                    rules={validationSchema}
                    render={({ field: { value, onChange } }) => (
                        <PhoneInput
                            value={value}
                            onChange={onChange}
                            className='inputField mb-8 w-full'
                            masks={countriesMask}
                            enableSearch
                            country={"fr"}
                        />
                    )}
                />

                {errors && errors[name]?.type === "required" && (
                    <span className="error">{errors[name]?.message}</span>
                )}
                {errors && errors[name]?.type === "minLength" && (
                    <span className="error">{errors[name]?.message}</span>
                )}
            </div>
        </div>)
};
export { PhoneFieldInput };